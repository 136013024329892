export const formatMoney = (amount) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });

  return formatter.format(amount);
};

export const getStatusColor = (status) => {
  switch (status) {
    case "COMPLETED":
      return "green";
    case "CANCEL":
      return "red";
    case "ACTIVE":
      return "blue";
    default:
      return "inherit";
  }
};

export const getStatusColorWithdraw = (status) => {
  switch (status) {
    case 1:
      return "#FFD700";
    case 0:
      return "red";
    case 2:
      return "blue";
    default:
      return "inherit";
  }
};

export const getStatusP2PColor = (status) => {
  switch (status) {
    case "PENDING":
      return "#FFD700";
    case "SUCCESS":
      return "#008000";
    case "SELL":
      return "#0000FF";
    case "CONFIRM":
      return "#00FF00";
    case "CANCEL":
      return "#FF0000";
    case "REPORT":
      return "#800080";
    case "DECIDED":
      return "#FFA500";
    default:
      return "";
  }
};
