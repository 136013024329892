/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import {
  port,
  portImg,
  portNode,
  portNowMarket,
  portStatistical,
} from "../port";

export default {
  CreateApiS: (url, data, token) => {
    if (token) {
      token = JSON.parse(localStorage.getItem("auth"));
    }
    return axios(`${port}${url}`, {
      headers: {
        Authorization: "Bearer " + token?.token,
      },

      method: "POST",
      data,
    });
  },
  CreateApiSV2: (url, data, token) => {
    if (token) {
      token = JSON.parse(localStorage.getItem("auth"));
    }
    return axios(`${portStatistical}${url}`, {
      headers: {
        Authorization: "Bearer " + token?.token,
      },

      method: "POST",
      data,
    });
  },
  CreateApiUploadSV2: (url, data, token) => {
    if (token) {
      token = JSON.parse(localStorage.getItem("auth"));
    }
    return axios(`${portImg}${url}`, {
      headers: {
        Authorization: "Bearer " + token?.token,
        "Content-Type": "multipart/form-data",
      },

      method: "POST",
      data,
    });
  },
  CreateApiPutSV2: (url, data, token) => {
    if (token) {
      token = JSON.parse(localStorage.getItem("auth"));
    }
    return axios(`${portStatistical}${url}`, {
      headers: {
        Authorization: "Bearer " + token?.token,
      },

      method: "PUT",
      data,
    });
  },
  CreateApiDeleteSV2: (url, data, token) => {
    if (token) {
      token = JSON.parse(localStorage.getItem("auth"));
    }
    return axios(`${portStatistical}${url}`, {
      headers: {
        Authorization: "Bearer " + token?.token,
      },

      method: "DELETE",
      data,
    });
  },
  CreateApiGetNode: (url, data, token) => {
    if (token) {
      token = JSON.parse(localStorage.getItem("auth"));
    }
    return axios(`${portNode}${url}`, {
      headers: {
        Authorization: "Bearer " + token?.token,
      },
      method: "GET",
      params: data,
    });
  },
  CreateApiGet: (url, token) => {
    if (token) {
      token = JSON.parse(localStorage.getItem("auth"));
    }
    return axios(`${port}${url}`, {
      headers: {
        Authorization: "Bearer " + token?.token,
      },

      method: "GET",
    });
  },
  CreateApiGetV2: (url, token) => {
    if (token) {
      token = JSON.parse(localStorage.getItem("auth"));
    }
    return axios(`${portStatistical}${url}`, {
      headers: {
        Authorization: "Bearer " + token?.token,
      },

      method: "GET",
    });
  },
  CreateApiSS: (url, data) => {
    return axios(`${port}${url}`, {
      method: "POST",
      data,
    });
  },

  CreateApiSR: (url, data) => {
    return axios(`${port}${url}`, {
      method: "POST",
      data,
    });
  },
  CreateApiSSS: (url, token) => {
    if (token) {
      token = JSON.parse(localStorage.getItem("auth"));
    }
    return axios(`${port}${url}`, {
      headers: {
        Authorization: "Bearer " + token?.token,
      },

      method: "POST",
    });
  },
  CreateApiSSSS: (url) => {
    return axios(`${port}${url}`, {
      method: "POST",
    });
  },
  CreateApiSSSSS: (url) => {
    return axios(`${port}${url}`, {
      method: "GET",
    });
  },
};
