import { useState } from "react";

import { Avatar, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { actionUploadImage } from "../../redux/reducers/brandReducer";
import LoaderStyleOnes from "../Loaders/LoaderStyleOne";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
const FileUploaderSingle = ({ setValue, name, error, trigger, image }) => {
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const { uploadFileLoading } = useSelector((state) => state.brand);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    onDrop: (acceptedFiles) => {
      try {
        const img = new FormData();
        img.append("file", acceptedFiles[0]);
        dispatch(actionUploadImage(img)).then((res) => {
          if (!res?.error) {
            setFiles(acceptedFiles.map((file) => Object.assign(file)));
            setValue(name, res?.data?.url);
            trigger(name);
          }
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },
  });

  const img = files.map((file, index) => (
    <Avatar
      key={file.name}
      sx={{ width: 200, height: 200 }}
      alt={file.name}
      className="single-file-image rounded-xl"
      src={URL.createObjectURL(file)}
    />
  ));

  return (
    <div>
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{
          border: `${Boolean(error) ? "1px solid #e53e3e" : ""}`,
          borderRadius: "50%",
          cursor: "pointer",
          position: "relative",
          paddingTop: Boolean(error) ? "0.625rem" : "0", // Adjust the padding based on your requirements
        }}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {files.length ? (
            img
          ) : image ? (
            <Avatar sx={{ width: 200, height: 200 }} src={image} />
          ) : (
            <Avatar
              sx={{ width: 200, height: 200 }}
              src={`/assets/images/kyc/${name}.png`}
            />
          )}
        </Box>

        {uploadFileLoading ? (
          <Box
            sx={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              top: "50%",
              left: "50%",
            }}
          >
            <LoaderStyleOnes />
          </Box>
        ) : (
          <Box
            sx={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              top: "50%",
              left: "50%",
            }}
          >
            <CloudUploadIcon color="action" fontSize="large" />
          </Box>
        )}
      </Box>
      {error && error.message ? (
        <span className="text-xs font-semibold pl-2.5 mt-0.5 text-qred">
          {error.message}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default FileUploaderSingle;
