import {
  Box,
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useDebounce } from "use-debounce";
import empty from "../../../asset/img/empty.png";
import CustomAutocomplete from "../../../components/RHFCom/CustomAutocomplete";
import { formatMoney, getStatusColor } from "../../../core/convertNumber";
import {
  actionDetailGiftCardPartner,
  actionFilterGiftCardPartner,
  actionGetHistoryGiftCardPartner,
} from "../../../redux/reducers/giftCardReducer";
import DetailsGiftcard from "./DetailsGiftcard";
function HistoryBuyGiftCard() {
  const {
    filterGiftCardLoading,
    filterGiftCardData,
    getListHistoryGiftCardData,
    getListHistoryGiftCardLoading,
  } = useSelector((state) => state.giftCard);

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDetails, setOpenDetails] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    keyword: "",
    categories: "",
    countries: "",
    status: null,
  });

  const [keywordSearch] = useDebounce(dataFilter?.keyword, 1000);
  const handleCloseDetails = () => setOpenDetails(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleSearch = (event) => {
    setDataFilter({ ...dataFilter, keyword: event.target?.value });
  };

  const handleOpenDetails = (id) => {
    setOpenDetails(true);
    dispatch(actionDetailGiftCardPartner(id));
  };

  useEffect(() => {
    dispatch(actionFilterGiftCardPartner());
  }, []);

  useEffect(() => {
    const data = {
      page: page,
      limit: rowsPerPage,
      keyword: keywordSearch,
      countries: dataFilter?.countries,
      categories: dataFilter?.categories,
      status: dataFilter?.status === "" ? "" : dataFilter?.status,
    };
    dispatch(actionGetHistoryGiftCardPartner(data));
  }, [page, rowsPerPage, keywordSearch, dataFilter]);

  return (
    <>
      <Box sx={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Keyword"
          variant="outlined"
          onChange={handleSearch}
        />
        <CustomAutocomplete
          fullWidth
          options={
            filterGiftCardData?.countries?.map((item) => {
              return {
                label: item?.name,
                value: item?.value,
              };
            }) || []
          }
          renderInput={(params) => <TextField {...params} label="Countries" />}
          onChange={(_, option) => {
            setPage(1);
            setDataFilter({
              ...dataFilter,
              countries: option ? option.value : "",
            });
          }}
        />
        <CustomAutocomplete
          fullWidth
          options={
            filterGiftCardData?.categories?.map((item) => {
              return {
                label: item?.name,
                value: item?.value,
              };
            }) || []
          }
          renderInput={(params) => <TextField {...params} label="Categories" />}
          onChange={(_, option) => {
            setPage(1);
            setDataFilter({
              ...dataFilter,
              categories: option ? option.value : "",
            });
          }}
        />

        <CustomAutocomplete
          fullWidth
          options={[
            {
              label: "ACTIVE",
              value: "ACTIVE",
            },
            {
              label: "COMPLETED",
              value: "COMPLETED",
            },
            {
              label: "CANCEL",
              value: "CANCEL",
            },
          ]}
          renderInput={(params) => <TextField {...params} label="Status" />}
          onChange={(_, option) => {
            setPage(1);
            setDataFilter({
              ...dataFilter,
              status: option ? option.value : "",
            });
          }}
        />
      </Box>
      <TableContainer component={Paper} style={{ marginBottom: "0px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">STT</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">
                {" "}
                <p
                  style={{
                    minWidth: "max-content",
                    marginBottom: "0px !important",
                  }}
                >
                  {" "}
                  Amount Coin
                </p>
              </TableCell>
              <TableCell align="center">
                <p
                  style={{
                    minWidth: "max-content",
                    marginBottom: "0px !important",
                  }}
                >
                  {" "}
                  Denomination
                </p>
              </TableCell>
              <TableCell align="center">Country</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Fee</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">
                {" "}
                <p
                  style={{
                    minWidth: "max-content",
                    marginBottom: "0px !important",
                  }}
                >
                  {" "}
                  Buy Date
                </p>
              </TableCell>
              <TableCell align="center">
                {" "}
                <p
                  style={{
                    minWidth: "max-content",
                    marginBottom: "0px !important",
                  }}
                >
                  {" "}
                  End Date
                </p>
              </TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getListHistoryGiftCardLoading || filterGiftCardLoading ? (
              Array.from({ length: 10 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>{" "}
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>{" "}
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>{" "}
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                </TableRow>
              ))
            ) : getListHistoryGiftCardData &&
              getListHistoryGiftCardData?.count > 0 ? (
              getListHistoryGiftCardData?.rows?.map((e, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {i + 1 + (page - 1) * rowsPerPage}
                  </TableCell>
                  <TableCell align="center">
                    <img
                      style={{ width: "80px" }}
                      src={e?.imageUrl}
                      alt={e?.name}
                      loading="lazy"
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: "150px" }}>
                    {e?.name}{" "}
                  </TableCell>
                  <TableCell align="center">{e?.user?.username} </TableCell>
                  <TableCell align="center">{e?.user?.email} </TableCell>
                  <TableCell align="center">
                    {" "}
                    {e?.amountCoin ? formatMoney(e?.amountCoin) : 0} {e?.coin}
                  </TableCell>

                  <TableCell align="center">
                    {" "}
                    {e?.denomination ? formatMoney(e?.denomination) : 0}{" "}
                    {e?.currency}
                  </TableCell>
                  <TableCell align="center">
                    {e?.countryName} ({e?.countryCode}){" "}
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      sx={{
                        maxWidth: "300px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {e?.categories}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {e?.fee ? e?.fee?.toFixed(2) : e?.fee}%{" "}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: getStatusColor(e?.status) }}
                  >
                    {e?.status}{" "}
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: "150px" }}>
                    <Typography noWrap sx={{ fontSize: "14px" }}>
                      {" "}
                      {dayjs(e?.createdAt).format("YYYY-MM-DD h:mm:ss A")}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: "150px" }}>
                    {e?.endDate}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleOpenDetails(e?.id)}
                    >
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={15} align="center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={empty}
                      style={{ maxWidth: "150px", margin: "20px 0" }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ paddingBottom: "20px", padding: "10px" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={getListHistoryGiftCardData?.count || 0}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <DetailsGiftcard
        openDetails={openDetails}
        handleCloseDetails={handleCloseDetails}
      />
    </>
  );
}

export default HistoryBuyGiftCard;
