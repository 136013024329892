import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import authReducer from "./reducers/authReducers";
import isDifferentReducer from "./reducers/isDifferentReducer";
import videoReducer from "./reducers/videoReducer";
import adminReducer from "./reducers/adminReducer";
import statisticalReducer from "./reducers/statisticalReducer";
import widthdrawReducer from "./reducers/widthdrawReducer";
import depositReducer from "./reducers/depositReducer";
import coinReducer from "./reducers/coinReducer";
import currencyReducer from "./reducers/currencyReducer";
import GetListReducer from "./reducers/GetListReducer";
import feeReducer from "./reducers/feeReducer";
import adsReducer from "./reducers/adsReducer";
import kycCompleted from "./reducers/kycCompletedReducer";
import transaction from "./reducers/transactionReducer";
import history from "./reducers/historyReducer";
import giftCard from "./reducers/giftCardReducer";
import p2p from "./reducers/p2pReducer";
import brand from "./reducers/brandReducer";
import giftCardSystem from "./reducers/giftCardSystemReducer";
import trackingBalance from "./reducers/trackingBalanceReducer";
import masterCard from "./reducers/masterCardReducer";
import businessReducer from "./reducers/businessReducer";
import kycReducer from "./reducers/kycReducer";
import voucher from "./reducers/listVoucherReducer";
let reducer = combineReducers({
  auth: authReducer,
  isDifferent: isDifferentReducer,
  video: videoReducer,
  admin: adminReducer,
  widthdraw: widthdrawReducer,
  deposit: depositReducer,
  statistical: statisticalReducer,
  coin: coinReducer,
  currency: currencyReducer,
  getlist: GetListReducer,
  fee: feeReducer,
  ads: adsReducer,
  kycCompleted: kycCompleted,
  transaction: transaction,
  history: history,
  giftCard: giftCard,
  p2p: p2p,
  brand: brand,
  giftCardSystem: giftCardSystem,
  trackingBalance: trackingBalance,
  masterCard: masterCard,
  business: businessReducer,
  kyc: kycReducer,
  voucher: voucher,
});
const middleware = (s) => (next) => (action) => {
  if (typeof action === "function") {
    return action(s.dispatch, s.getState());
  }
  return next(action);
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = createStore(reducer, composeEnhancers(applyMiddleware(middleware)));

export default store;
