import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
function Sildebar2({ SetSidebar, sidebar }) {
  let user = JSON.parse(localStorage.getItem("auth"));

  useEffect(() => {
    const body = document.querySelector("body");
    const modeSwitch = body.querySelector(".toggle-switch");
    const modeText = body.querySelector(".mode-text");
    modeSwitch.addEventListener("click", () => {
      body.classList.toggle("dark");

      if (body.classList.contains("dark")) {
        modeText.innerText = "Light mode";
      } else {
        modeText.innerText = "Dark mode";
      }
    });
  }, []);

  let navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("vndusd");
    navigate("/login");
  };

  return (
    <nav className={`${sidebar ? "sidebar" : "sidebar close"}`}>
      <header>
        <div className="image-text">
          <span className="image" style={{ marginRight: "7px" }}>
            <img src="logoS.png" alt="" />
          </span>

          <div className="text logo-text">
            <span className="name">Admin</span>
            <span className="profession">Administrators</span>
          </div>
        </div>

        <i
          className="bx bx-chevron-right toggle"
          onClick={() => SetSidebar(!sidebar)}
        ></i>
      </header>

      <div className="menu-bar">
        <div className="menu">
          {/* <li className="search-box">
                        <i className='bx bx-search icon'></i>
                        <input type="text" placeholder="Search..." />
                    </li> */}

          <ul className="menu-links">
            {user?.id == 1 ? (
              <>
                <li className="nav-link">
                  <NavLink to="/">
                    <i className="bx bxs-user icon"></i>
                    <span className="text nav-text">User</span>
                  </NavLink>
                </li>

                <li className="nav-link">
                  <NavLink to="manage-coin">
                    <i className="bx bxs-coin icon"></i>
                    <span className="text nav-text">Manage Coin</span>
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="manage-currency">
                    <i className="bx bxs-coin icon"></i>
                    <span className="text nav-text">Manage Currency</span>
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="manage-voucher">
                    {/* <i className="bx bxs-coin icon"></i> */}
                    <span className="icon bx">
                      <ConfirmationNumberIcon />
                    </span>
                    <span className="text nav-text">Manage Voucher</span>
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="manage-master-card">
                    <i className="bx bxs-card icon"></i>
                    <span className="text nav-text">Master Card</span>
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="widthdraw">
                    <i className="bx bxs-wallet icon"></i>
                    <span className="text nav-text">Widthdraw</span>
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="Deposit">
                    <i className="bx bx-money icon"></i>
                    <span className="text nav-text">Deposit</span>
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="transfer">
                    <i className="bx bx-transfer icon"></i>
                    <span className="text nav-text">Transfer</span>
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="manage-brand">
                    <i className="bx bxs-store icon"></i>
                    <span className="text nav-text">Manage Brand</span>
                  </NavLink>
                </li>

                <li className="nav-link">
                  <NavLink to="gift-card-partner">
                    <i className="bx bx-gift icon"></i>
                    <span className="text nav-text">Giftcard Partner</span>
                  </NavLink>
                </li>

                {/* <li className="nav-link">
                  <NavLink to="gift-card-system">
                    <i className="bx bxs-gift icon"></i>
                    <span className="text nav-text">Giftcard System</span>
                  </NavLink>
                </li> */}

                <li className="nav-link">
                  <NavLink to="tracking-balance">
                    <i className="bx bx-history icon"></i>
                    <span className="text nav-text">Tracking Balance</span>
                  </NavLink>
                </li>

                <li className="nav-link">
                  <NavLink to="p2p">
                    <i className="bx bx-transfer-alt icon"></i>
                    <span className="text nav-text">P2P</span>
                  </NavLink>
                </li>

                {/* <li className="nav-link">
                  <NavLink to="kyc-completed">
                    <i className="bx bx-sm bxs-user-check icon"></i>
                    <span className="text nav-text">KYC Completed</span>
                  </NavLink>
                </li> */}
                <li className="nav-link">
                  <NavLink to="verify-business">
                    <i className="bx bxs-store icon"></i>
                    <span className="text nav-text">Verify Business</span>
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="kyc">
                    <i className="bx bxs-user-check icon"></i>
                    <span className="text nav-text">KYC</span>
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="transaction">
                    <i className="bx bx-sm bxs-wallet icon"></i>
                    <span className="text nav-text">Transaction</span>
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="config">
                    <i className="bx bxs-cog icon"></i>
                    <span className="text nav-text">Config</span>
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                {" "}
                <li className="nav-link">
                  <NavLink to="/">
                    <i className="bx bxs-user icon"></i>
                    <span className="text nav-text">User</span>
                  </NavLink>
                </li>
                <li className="nav-link">
                  <NavLink to="widthdraw">
                    <i className="bx bxs-wallet icon"></i>
                    <span className="text nav-text">Widthdraw</span>
                  </NavLink>
                </li>{" "}
              </>
            )}
          </ul>
        </div>
        <div className="bottom-content">
          <li className="">
            <a href="#" onClick={logout}>
              <i className="bx bx-log-out icon"></i>
              <span className="text nav-text">Logout</span>
            </a>
          </li>

          <li className="mode">
            <div className="sun-moon">
              <i className="bx bx-moon icon moon"></i>
              <i className="bx bx-sun icon sun"></i>
            </div>
            <span className="mode-text text">Dark mode</span>

            <div className="toggle-switch">
              <span className="switch"></span>
            </div>
          </li>
          {/* <li style={{ display: "block" }}>
            <div
              className="language-selector"
              style={{ width: "100%", height: "100%" }}
            >
              <i className="icon">
                <LanguageIcon />
              </i>
              <div id="google_translate_element"></div>
            </div>
          </li> */}
        </div>
      </div>
    </nav>
  );
}

export default Sildebar2;
