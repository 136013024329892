import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import { ErrorMessage } from "../../core/message";
import reduxToolkit from "../../core/reduxToolkit";
import { Loadding } from "./isDifferentReducer";

const initialState = {
  getListDeposit: "",
  getpagedeposit: 1,
};

export const HandleGetListDeposit = (page) => {
  return async (dispatch) => {
    dispatch(Loadding(true));

    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/getListDepositVNDAdmin",
        {
          limit: 10,
          page: page,
        },
        true
      );
      dispatch(GetListDeposit({ res: res?.data?.data, page: page }));
    } catch (error) {}
    dispatch(Loadding(false));
  };
};

export const handleSubmitDeposit = (id, page) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/activeDepositVND",
        {
          id,
        },
        true
      );
      toast.success(`🎉 Approve successful `);
      dispatch(HandleGetListDeposit(page));
    } catch (error) {
      ErrorMessage(error?.response?.data?.message);
    }
  };
};
export const HandleCancelDeposit = (id, note, page, symbol) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/cancelDepositVND",
        {
          id: id,
          note: note,
        },
        true
      );
      dispatch(HandleGetListDeposit(page, symbol));
      toast.success(`🎉 Cancel success `);
    } catch (error) {
      ErrorMessage(error?.response?.data?.message);
    }
  };
};
let { reducer, action, TYPE } = reduxToolkit({
  name: "deposit",
  initialState,
  reducers: {
    GetListDeposit: function (state, action) {
      return {
        ...state,
        getListDeposit: action.payload?.res,
        getpagedeposit: action.payload?.page,
      };
    },
  },
});

export default reducer;
export const WIDTHDRAW = TYPE;
export const { GetListDeposit } = action;
