import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";

const initialState = {
  listHistoryTransfer: "",
  isListHistoryTransfer: false,
  listUserNowMarket: "",
  isListUserNowMarket: false,
  blockUserNowMarket: "",
  isBlockUserNowMarket: false,
  isError: false,
};

export const GetListHistoryTransfer = (data) => {
  return async (dispatch) => {
    const { page, limit, keyword = "", userid } = data;
    dispatch(getListHistoryTransferStart());
    try {
      let res = await CreateApi.CreateApiGet(
        `api/v1/admin/manage/history-transfer?page=${page || 1}&limit=${
          limit || 10
        }${keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""}`,
        true
      );
      dispatch(getListHistoryTransferSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getListHistoryTransferFail());
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "history",
  initialState,
  reducers: {
    getListHistoryTransferStart: (state) => {
      state.isListHistoryTransfer = true;
      state.isError = initialState.isError;
    },
    getListHistoryTransferSuccess: (state, action) => {
      state.listHistoryTransfer = action.payload;
      state.isListHistoryTransfer = false;
      state.isError = initialState.isError;
    },
    getListHistoryTransferFail: (state, action) => {
      state.isError = action.payload;
      state.isListHistoryTransfer = false;
    },
  },
});

export default reducer;
export const history = TYPE;
export const {
  getListHistoryTransferStart,
  getListHistoryTransferSuccess,
  getListHistoryTransferFail,
} = action;
