import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";
import { Loadding } from "./isDifferentReducer";

const initialState = {
  getListStatistical: "",
  getPageListStatistical: 1,
};

export const HandleGetListStatistical = (symbol, page, limit, value) => {
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/getWalletAll",
        {
          symbol: symbol,
          page: page,
          limit: limit,
          keyWork: value,
          sort: true,
        },
        true
      );
      dispatch(GetListStatistical({ res: res?.data?.data, page: page }));
    } catch (error) {}
    dispatch(Loadding(false));
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "statistical",
  initialState,
  reducers: {
    GetListStatistical: function (state, action) {
      return {
        ...state,
        getListStatistical: action.payload?.res,
        getPageListStatistical: action.payload?.page,
      };
    },
  },
});

export default reducer;
export const GETLIST = TYPE;
export const { GetListStatistical } = action;
