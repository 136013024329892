import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";

const initialState = {
  getVerifyUserData: null,
  getVerifyUserLoading: false,
  getVerifyUserError: "",

  updateUserData: null,
  updateUserLoading: false,
  updateUserError: "",

  detailUserData: null,
  detailUserLoading: false,
  detailUserError: "",
};

export const actionGetVerifyUser = (data, isLoading = true) => {
  return async (dispatch) => {
    dispatch(getVerifyUserStart(isLoading));
    try {
      const { page, limit, keyword, status } = data;
      const apiEndpoint = `api/v1/admin/manage-user/get-verify-user?page=${
        page || 1
      }&limit=${limit || 10}${
        keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
      }${status ? `&status=${encodeURIComponent(status)}` : ""}
      `;

      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getVerifyUserSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getVerifyUserFail());
    }
  };
};

export const actionDetailUser = (id) => {
  return async (dispatch) => {
    dispatch(detailUserStart());
    try {
      const apiEndpoint = `api/v1/admin/manage-user/get-verify-user/${id}`;
      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(detailUserSuccess(res?.data?.data));
        return res?.data;
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(detailUserFail());
    }
  };
};

export const actionUpdateUser = (data) => {
  return async (dispatch) => {
    dispatch(updateUserStart());
    const id = toast.loading("Please wait....");
    try {
      const apiEndpoint = `api/v1/admin/manage-user/verify-user`;
      let res = await CreateApi.CreateApiSV2(apiEndpoint, data, true);
      if (res.status === 200) {
        dispatch(updateUserSuccess(res?.data?.data));
        toast.update(id, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        return res?.data;
      } else {
        toast.update(id, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(updateUserFail());
      toast.update(id, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "business",
  initialState,
  reducers: {
    getVerifyUserStart: (state, isLoading) => {
      state.getVerifyUserLoading = isLoading.payload;
    },
    getVerifyUserSuccess: (state, action) => {
      state.getVerifyUserLoading = false;
      state.getVerifyUserData = action.payload;
      state.getVerifyUserError = "";
    },
    getVerifyUserFail: (state, action) => {
      state.getVerifyUserLoading = false;
      state.getVerifyUserError = action.payload;
      state.getVerifyUserData = {};
    },

    detailUserStart: (state) => {
      state.detailUserLoading = true;
    },
    detailUserSuccess: (state, action) => {
      state.detailUserLoading = false;
      state.detailUserData = action.payload;
      state.detailUserError = "";
    },
    detailUserFail: (state, action) => {
      state.detailUserLoading = false;
      state.detailUserError = action.payload;
      state.detailUserData = {};
    },

    updateUserStart: (state) => {
      state.updateUserLoading = true;
    },
    updateUserSuccess: (state, action) => {
      state.updateUserLoading = false;
      state.updateUserData = action.payload;
      state.updateUserError = "";
    },
    updateUserFail: (state, action) => {
      state.updateUserLoading = false;
      state.updateUserError = action.payload;
      state.updateUserData = {};
    },
  },
});

export default reducer;
export const kyc = TYPE;
export const {
  getVerifyUserStart,
  getVerifyUserSuccess,
  getVerifyUserFail,
  detailUserStart,
  detailUserSuccess,
  detailUserFail,
  updateUserStart,
  updateUserSuccess,
  updateUserFail,
} = action;
