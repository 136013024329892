import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";
import { Loadding } from "./isDifferentReducer";

const initialState = {
  listkyc: "",
  pagelistkyc: 1,
  inputListKyc: "",
  listActiveUser: "",
  listuser: "",
  pagelistuser: 1,
  inputListUser: "",
  database: "",
  modeTabDetail: 1,
  isGetBalanceByUser: false,
  listBalanceByUser: null,
  isGetHistoryDeposit: false,
  listHistoryDeposit: null,
  isGetHistoryWithdrawal: false,
  listHistoryWithdrawal: null,
  isGetHistorySwap: false,
  listHistorySwap: null,
  isGetHistoryTransfer: false,
  listHistoryTransfer: null,
  isUpdateCoinUser: false,
  isDeleteCoinUser: false,
  isBlockUser: false,
  isResetPassword: false,
  isError: false,
};

export const HandleGetUserKyc = (page, data) => {
  return async (dispatch) => {
    if (!data) {
      dispatch(Loadding(true));

      try {
        let res = await CreateApi.CreateApiS(
          "api/admin/getKycUserPendding",
          {
            limit: 10,
            page: page,
          },
          true
        );
        dispatch(GetAllUserKyc({ res: res?.data?.data, page: page }));
      } catch (error) {}
      dispatch(Loadding(false));
    }
  };
};
export const HandleGetUserKycSearch = (page, keywork) => {
  return async (dispatch) => {
    var data = {
      limit: 10,
      page: page,
      keywork: keywork,
    };
    let res = await CreateApi.CreateApiS(
      "api/admin/getKycUserPendingToUserName",
      data,
      true
    );
    dispatch(
      GetAllUserKyc({ res: res?.data?.data, page: page, input: keywork })
    );
    dispatch(Loadding(false));
  };
};

// export const HandleGetListUserActive = (page) => {
//   return async (dispatch) => {
//     let user = JSON.parse(localStorage.getItem("userLogin"));
//     var data = {
//       api_passer: {
//         key_passer: "getAllUserToAdmin",
//         token: user.token,
//         limit: 10,
//         page: page,
//       },
//     };
//     socket.emit("/apiKan", data);
//     socket.on("getAllUserToAdmin", (res) => {
//       if (res.status >= 200 && res.status < 300) {
//         dispatch(GetAllActiveUser(res?.data));
//       } else {
//       }
//       socket.off("getAllUserToAdmin");
//     });
//   };
// };

export const HandleGetListUser = (data) => {
  return async (dispatch) => {
    const { page, limit, keyword = "" } = data;
    dispatch(Loadding(data?.isLoading === false ? false : true));
    try {
      let res = await CreateApi.CreateApiGet(
        `api/admin/getAllUser?page=${page || 1}&limit=${limit || 10}${
          keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
        }`,
        true
      );
      dispatch(GetAllUser({ res: res?.data?.data, page: page }));
    } catch (error) {}
    dispatch(Loadding(false));
  };
};

export const HandleGetListUserSearch = (page, keyword) => {
  return async (dispatch) => {
    var data = {
      limit: 10,
      page: page,
      keywork: keyword,
    };
    let res = await CreateApi.CreateApiS(
      "api/admin/sreachusertousername",
      data,
      true
    );
    dispatch(GetAllUser({ res: res?.data?.data, page: page, input: keyword }));
    dispatch(Loadding(false));
  };
};

// export const HandleGetDataBase = (page) => {
//   return async (dispatch) => {
//     let user = JSON.parse(localStorage.getItem("userLogin"));
//     var data = {
//       api_passer: {
//         key_passer: "getListUser",
//         token: user.token,
//         limit: 10,
//         page: page,
//       },
//     };
//     socket.emit("/apiKan", data);
//     socket.on("getListUser", (res) => {
//       if (res.status >= 200 && res.status < 300) {
//         dispatch(GetDataBase(res?.data));
//       } else {
//       }
//     });
//   };
// };

export const fetchGetBalanceByUser = (page, userId, rowsPerPage) => {
  return async (dispatch) => {
    dispatch(getBalanceByUserStart());
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/getWalletCodesAdmin",
        {
          limit: rowsPerPage,
          page: page,
          userid: userId,
        },
        true
      );
      dispatch(getBalanceByUserSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getBalanceByUserFail());
  };
};

export const fetchGetHistoryDeposit = (data) => {
  return async (dispatch) => {
    const { page, limit, keyword = "", userid } = data;
    dispatch(getHistoryDepositStart());
    try {
      let res = await CreateApi.CreateApiGet(
        `api/v1/admin/manage/history-deposit?page=${page || 1}&limit=${
          limit || 10
        }${keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""}${
          userid ? `&userid=${encodeURIComponent(userid)}` : ""
        }`,
        true
      );
      dispatch(getHistoryDepositSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getHistoryDepositFail());
  };
};

export const fetchGetHistoryWithdrawal = (page, userId, rowsPerPage) => {
  return async (dispatch) => {
    dispatch(getHistoryWithdrawalStart());
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/getAllHistoryRut",
        {
          limit: rowsPerPage,
          page: page,
          userid: userId,
        },
        true
      );
      dispatch(getHistoryWithdrawalSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getHistoryWithdrawalFail());
  };
};

export const fetchGetHistorySwap = (page, userId, rowsPerPage) => {
  return async (dispatch) => {
    dispatch(getHistorySwapStart());
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/getAllHistorySwap",
        {
          limit: rowsPerPage,
          page: page,
          userid: userId,
        },
        true
      );
      dispatch(getHistorySwapSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getHistorySwapFail());
  };
};

export const fetchGetHistoryTransfer = (page, userId, rowsPerPage) => {
  return async (dispatch) => {
    dispatch(getHistoryTransferStart());
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/getAllHistoryTranffer",
        {
          limit: rowsPerPage,
          page: page,
          userid: userId,
        },
        true
      );
      dispatch(getHistoryTransferSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getHistoryTransferFail());
  };
};

export const fetchUpdateCoinUser = (body, handleClose) => {
  return async (dispatch) => {
    dispatch(updateCoinUserStart());
    try {
      let res = await CreateApi.CreateApiPutSV2(
        `api/v1/admin/manage-user/update-wallet/${body?.id}`,
        { amount: body?.amount, code: body.code },
        true
      );
      toast.success(`🎉 ${res?.data?.message} `);
      dispatch(fetchGetBalanceByUser(1, body?.userId, 10));
      dispatch(updateCoinUserSuccess(res?.data?.data));
      handleClose();
    } catch (error) {}
    dispatch(updateCoinUserFail());
  };
};

export const fetchDeleteCoinUser = (body, setAnchorEl) => {
  return async (dispatch) => {
    dispatch(deleteCoinUserStart());
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/deleteWalletCode",
        { id: body?.id },
        true
      );
      setAnchorEl(null);
      toast.success(`🎉 ${res?.data?.message} `);
      dispatch(fetchGetBalanceByUser(1, body?.userId, 10));
      dispatch(deleteCoinUserSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(deleteCoinUserFail());
  };
};

export const fetchBlockUser = (id, isBlock, keyword) => {
  return async (dispatch) => {
    dispatch(blockUserStart());
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/blockuser",
        { userid: id, isBlock: isBlock },
        true
      );
      dispatch(blockUserSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(blockUserFail());
  };
};

export const fetchResetPassword = (id, password) => {
  return async (dispatch) => {
    dispatch(resetPasswordStart());
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/resetPasswordUser",
        { userid: id, password: password },
        true
      );
      toast.success(`🎉 ${res?.data?.message} `);
      dispatch(resetPasswordSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(resetPasswordFail());
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "admin",
  initialState,
  reducers: {
    GetAllUserKyc: (state, action) => {
      return {
        ...state,
        listkyc: action.payload?.res,
        pagelistkyc: action.payload?.page,
        inputListKyc: action.payload?.input,
      };
    },
    GetAllActiveUser: (state, action) => {
      return {
        ...state,
        listActiveUser: action.payload,
      };
    },
    GetAllUser: (state, action) => {
      return {
        ...state,
        listuser: action.payload?.res,
        pagelistuser: action.payload?.page,
        inputListUser: action.payload?.input,
      };
    },
    GetDataBase: (state, action) => {
      return {
        ...state,
        database: action.payload,
      };
    },
    setModeTab: (state, action) => {
      return {
        ...state,
        modeTabDetail: action.payload,
      };
    },
    getBalanceByUserStart: (state) => {
      state.isGetBalanceByUser = true;
      state.isError = initialState.isError;
    },
    getBalanceByUserSuccess: (state, action) => {
      state.listBalanceByUser = action.payload;
      state.isGetBalanceByUser = false;
      state.isError = initialState.isError;
    },
    getBalanceByUserFail: (state, action) => {
      state.isError = action.payload;
      state.isGetBalanceByUser = false;
    },
    getHistoryDepositStart: (state) => {
      state.isGetHistoryDeposit = true;
      state.isError = initialState.isError;
    },
    getHistoryDepositSuccess: (state, action) => {
      state.listHistoryDeposit = action.payload;
      state.isGetHistoryDeposit = false;
      state.isError = initialState.isError;
    },
    getHistoryDepositFail: (state, action) => {
      state.isError = action.payload;
      state.isGetHistoryDeposit = false;
    },
    getHistoryWithdrawalStart: (state) => {
      state.isGetHistoryWithdrawal = true;
      state.isError = initialState.isError;
    },
    getHistoryWithdrawalSuccess: (state, action) => {
      state.listHistoryWithdrawal = action.payload;
      state.isGetHistoryWithdrawal = false;
      state.isError = initialState.isError;
    },
    getHistoryWithdrawalFail: (state, action) => {
      state.isError = action.payload;
      state.isGetHistoryWithdrawal = false;
    },
    getHistorySwapStart: (state) => {
      state.isGetHistorySwap = true;
      state.isError = initialState.isError;
    },
    getHistorySwapSuccess: (state, action) => {
      state.listHistorySwap = action.payload;
      state.isGetHistorySwap = false;
      state.isError = initialState.isError;
    },
    getHistorySwapFail: (state, action) => {
      state.isError = action.payload;
      state.isGetHistorySwap = false;
    },
    getHistoryTransferStart: (state) => {
      state.isGetHistoryTransfer = true;
      state.isError = initialState.isError;
    },
    getHistoryTransferSuccess: (state, action) => {
      state.listHistoryTransfer = action.payload;
      state.isGetHistoryTransfer = false;
      state.isError = initialState.isError;
    },
    getHistoryTransferFail: (state, action) => {
      state.isError = action.payload;
      state.isGetHistoryTransfer = false;
    },
    updateCoinUserStart: (state) => {
      state.isUpdateCoinUser = true;
      state.isError = initialState.isError;
    },
    updateCoinUserSuccess: (state, action) => {
      state.isUpdateCoinUser = false;
      state.isError = initialState.isError;
    },
    updateCoinUserFail: (state, action) => {
      state.isError = action.payload;
      state.isUpdateCoinUser = false;
    },
    deleteCoinUserStart: (state) => {
      state.isDeleteCoinUser = true;
      state.isError = initialState.isError;
    },
    deleteCoinUserSuccess: (state, action) => {
      state.isDeleteCoinUser = false;
      state.isError = initialState.isError;
    },
    deleteCoinUserFail: (state, action) => {
      state.isError = action.payload;
      state.isDeleteCoinUser = false;
    },
    blockUserStart: (state) => {
      state.isDeleteCoinUser = true;
      state.isError = initialState.isError;
    },
    blockUserSuccess: (state, action) => {
      state.isDeleteCoinUser = false;
      state.isError = initialState.isError;
    },
    blockUserFail: (state, action) => {
      state.isError = action.payload;
      state.isDeleteCoinUser = false;
    },
    resetPasswordStart: (state) => {
      state.isResetPassword = true;
      state.isError = initialState.isError;
    },
    resetPasswordSuccess: (state, action) => {
      state.isResetPassword = false;
      state.isError = initialState.isError;
    },
    resetPasswordFail: (state, action) => {
      state.isError = action.payload;
      state.isResetPassword = false;
    },
  },
});

export default reducer;
export const admin = TYPE;
export const {
  GetAllUserKyc,
  GetAllActiveUser,
  GetAllUser,
  GetDataBase,
  setModeTab,
  getBalanceByUserStart,
  getBalanceByUserSuccess,
  getBalanceByUserFail,
  getHistoryDepositStart,
  getHistoryDepositSuccess,
  getHistoryDepositFail,
  getHistoryWithdrawalStart,
  getHistoryWithdrawalSuccess,
  getHistoryWithdrawalFail,
  getHistorySwapStart,
  getHistorySwapSuccess,
  getHistorySwapFail,
  getHistoryTransferStart,
  getHistoryTransferSuccess,
  getHistoryTransferFail,
  updateCoinUserStart,
  updateCoinUserSuccess,
  updateCoinUserFail,
  deleteCoinUserStart,
  deleteCoinUserSuccess,
  deleteCoinUserFail,
  blockUserStart,
  blockUserSuccess,
  blockUserFail,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFail,
} = action;
