import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  Fab,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import { actionUploadBulkImage } from "../../../redux/reducers/brandReducer";
import { useDispatch } from "react-redux";

export const InputChatBox = ({ handleSend }) => {
  const [inputMessage, setInputMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const dispatch = useDispatch();
  const sendMessage = useCallback(() => {
    if (inputMessage.trim() !== "") {
      handleSend(inputMessage, selectedFiles);
      setInputMessage("");
      setSelectedFiles([]);
      setPreviewImages([]);
    }
  }, [inputMessage, selectedFiles, handleSend]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const newSelectedFiles = Array.from(files);
      console.log(files);
      const img = new FormData();
      newSelectedFiles.forEach((file) => {
        img.append("files", file);
      });
      dispatch(actionUploadBulkImage(img)).then((res) => {
        if (!res?.error) {
          setSelectedFiles((prevFiles) => [...prevFiles, ...res?.data]);
          // setSelectedFiles((prevFiles) => [...prevFiles, ...newSelectedFiles]);

          // Preview images before uploading
          const newPreviewImages = Array.from(files).map((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              setPreviewImages((prevImages) => [...prevImages, reader.result]);
            };
            reader.readAsDataURL(file);
          });
        }
      });
    }
  };

  const removeImage = (index) => {
    setPreviewImages((prevImages) => {
      const newImages = [...prevImages];
      newImages.splice(index, 1);
      return newImages;
    });

    setSelectedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  console.log(selectedFiles, previewImages);

  return (
    <>
      {previewImages.length > 0 && (
        <Box m={2}>
          <Typography variant="caption">Selected Images Preview:</Typography>
          <Box
            display="flex"
            alignItems="center"
            mt={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              gap: 1,
            }}
          >
            {previewImages.map((preview, index) => (
              <Box
                key={index}
                style={{ position: "relative", minHeight: "100px" }}
              >
                <img
                  src={preview}
                  alt={`Preview ${index}`}
                  style={{
                    marginRight: "8px",
                    maxWidth: "100px",
                    maxHeight: "100px",
                  }}
                />
                <IconButton
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  onClick={() => removeImage(index)}
                  size="small"
                  color="secondary"
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          zIndex: "100",
          background: "#fff",
          px: 2,
          pb: 2,
          pt: 1,
          gap: 2,
        }}
      >
        <TextField
          fullWidth
          type="text"
          placeholder="Enter to join the conversation"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <label htmlFor="upload-photo">
                  <input
                    style={{ display: "none" }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    onChange={handleFileChange}
                    multiple
                  />
                  <Fab
                    color="primary"
                    size="small"
                    component="span"
                    aria-label="add"
                  >
                    <CloudUploadIcon />
                  </Fab>
                </label>
              </InputAdornment>
            ),
          }}
          onKeyDown={handleKeyPress}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={sendMessage}
          sx={{ minWidth: "100px" }}
        >
          Send
        </Button>
      </Box>
    </>
  );
};
