import {
  Box,
  Button,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useDebounce } from "use-debounce";
import empty from "../../../asset/img/empty.png";
import { portImg } from "../../../port";
import {
  actionGetListBrand,
  actionGetListCountry,
  actionGetListPlace,
} from "../../../redux/reducers/brandReducer";
import {
  actionGetCoinP2P,
  actionGetCurrencyP2P,
  actionGetListP2P,
  actionGetStatusP2P,
  actionUpdateStatustP2P,
} from "../../../redux/reducers/p2pReducer";
import DetailsBrand from "./DetailsBrand";
import { actionFilterGiftCardPartner } from "../../../redux/reducers/giftCardReducer";
function ListBrand() {
  const {
    getCoinP2PData,
    getCoinP2PLoading,
    getCurrencyP2PData,
    getCurrencyP2PLoading,
    getStatusP2PData,
    getStatusP2PLoading,
    getListP2PData,
    getListP2PLoading,
  } = useSelector((state) => state.p2p);

  const {
    getListBrandData,
    getListBrandLoading,
    updateBrandData,
    updateBrandLoading,
    detailBrandData,
    detailBrandLoading,
    createBrandData,
    createBrandLoading,
    deleteBrandData,
    deleteBrandLoading,
  } = useSelector((state) => state.brand);

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDetails, setOpenDetails] = useState(false);
  const [detailBrand, setDetailBrand] = useState(null);
  const [dataFilter, setDataFilter] = useState({
    keyword: "",
  });
  const [keywordSearch] = useDebounce(dataFilter?.keyword, 1000);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
    setDetailBrand(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleSearch = (event) => {
    setDataFilter({ ...dataFilter, keyword: event.target?.value });
  };

  const handleOpenDetails = (brand) => {
    setOpenDetails(true);
    setDetailBrand(brand);
  };

  const handleUpdateStatus = (datas) => {
    const data = {
      page: page,
      limit: rowsPerPage,
      keyword: keywordSearch,
    };
    dispatch(actionGetListBrand(data));
  };

  const handleChangeActive = (id, active) => {
    // dispatch(
    //   actionUpdateStatustGiftCardPartner({ id: id, active: active })
    // ).then((res) => {
    //   if (res?.status)
    //     dispatch(
    //       actionGetGiftCardPartner(
    //         {
    //           page: page,
    //           limit: rowsPerPage,
    //           keyword: keywordSearch,
    //           countries: dataFilter?.countries,
    //           categories: dataFilter?.categories,
    //           active: dataFilter?.active === "" ? "" : dataFilter?.active,
    //         },
    //         false
    //       )
    //     );
    // });
  };

  useEffect(() => {
    dispatch(actionGetListCountry());
    dispatch(actionGetListPlace());
    dispatch(actionFilterGiftCardPartner());
  }, []);

  useEffect(() => {
    const data = {
      page: page,
      limit: rowsPerPage,
      keyword: keywordSearch,
    };
    dispatch(actionGetListBrand(data));
  }, [page, rowsPerPage, keywordSearch, dataFilter]);

  console.log(getListBrandData, "getListBrandData");

  return (
    <>
      <Box sx={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Keyword"
          variant="outlined"
          onChange={handleSearch}
        />
      </Box>
      <TableContainer component={Paper} style={{ marginBottom: "0px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">STT</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Country</TableCell>
              <TableCell align="center">Place</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getListBrandLoading ||
            getCoinP2PLoading ||
            getCurrencyP2PLoading ||
            getStatusP2PLoading ? (
              Array.from({ length: 10 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>{" "}
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>{" "}
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>{" "}
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                </TableRow>
              ))
            ) : getListBrandData && getListBrandData?.count > 0 ? (
              getListBrandData?.rows?.map((e, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {i + 1 + (page - 1) * rowsPerPage}
                  </TableCell>
                  <TableCell align="center">
                    <img
                      style={{ width: "80px", height: "80px" }}
                      src={portImg + e?.images}
                      alt={e?.name}
                      loading="lazy"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap variant="inherit">
                      {e?.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap variant="inherit">
                      {e?.country?.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap variant="inherit">
                      {e?.place?.name}{" "}
                    </Typography>
                  </TableCell>
                  <Tooltip title={e?.description}>
                    <TableCell sx={{ maxWidth: "350px" }}>
                      <Typography noWrap variant="inherit">
                        {e?.description}
                      </Typography>
                    </TableCell>
                  </Tooltip>

                  <TableCell align="center">
                    {" "}
                    <Switch
                      checked={e?.active}
                      onChange={() => handleChangeActive(e?.id, !e?.active)}
                      inputProps={{ "aria-label": "controlled" }}
                    />{" "}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={() => handleOpenDetails(e)}
                    >
                      <Typography
                        noWrap
                        sx={{ fontWeight: "500", fontSize: "14px" }}
                      >
                        Detail
                      </Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={empty}
                      style={{ maxWidth: "150px", margin: "20px 0" }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ paddingBottom: "20px", padding: "10px" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={getListBrandData?.count || 0}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <DetailsBrand
        detailBrand={detailBrand}
        openDetails={openDetails}
        handleCloseDetails={handleCloseDetails}
        handleUpdateStatus={handleUpdateStatus}
      />
    </>
  );
}

export default ListBrand;
