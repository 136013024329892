import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import React, { useEffect, useState } from "react";

import ListGiftCard from "./Components/ListGiftCard";
import HistoryIcon from "@mui/icons-material/History";
import HistoryBuyGiftCard from "./Components/HistoryBuyGiftCard";
function GiftCard() {
  const [menu, setMenu] = useState("COIN");

  const HandleMenu = () => {
    return (
      <ul style={{ width: "initial", padding: "0px 10px" }}>
        <li className="list active">
          <a onClick={() => setMenu("COIN")}>
            <span className="icon">
              <CardGiftcardIcon />
            </span>
            <span
              className="text"
              style={{ minWidth: "150px", paddingTop: "10px" }}
            >
              GIFTCARD PARTNER
            </span>
          </a>
        </li>
        <li className="list ">
          <a onClick={() => setMenu("VND")}>
            <span className="icon">
              <HistoryIcon />
            </span>
            <span
              className="text"
              style={{ minWidth: "150px", paddingTop: "10px" }}
            >
              HISTORY BUY GIFTCARD
            </span>
          </a>
        </li>
        <div className="indicator"></div>
      </ul>
    );
  };

  useEffect(() => {
    const list = document.querySelectorAll(".list");
    function activeMenu() {
      list.forEach((item) => {
        item.classList.remove("active");
        this.classList.add("active");
      });
    }
    list.forEach((item) => {
      item.addEventListener("click", activeMenu);
    });
  }, []);

  return (
    <>
      <div className="MiniMenu" style={{ paddingTop: "40px" }}>
        <div
          className="navigation"
          style={{
            background: "#A60A5C",
            width: "250px",
          }}
        >
          {HandleMenu()}
        </div>
      </div>
      <div className="content-wrapper">
        {menu == "COIN" && <ListGiftCard />}
        {menu == "VND" && <HistoryBuyGiftCard />}
      </div>
    </>
  );
}

export default GiftCard;
