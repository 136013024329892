import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import { ErrorMessage } from "../../core/message";
import reduxToolkit from "../../core/reduxToolkit";
import socket from "../../socket";
import { Loadding } from "./isDifferentReducer";

const initialState = {
  getallwidthdraw: "",
  getpagewithdraw: 1,
  getfee: "",
  getallWidthDrawCoin: "",
  getpagewithdrawcoin: 1,
};

export const HandleGetListCoin = (data) => {
  return async (dispatch) => {
    const { page, limit, keyword = "", symbol = "NOW" } = data;
    dispatch(Loadding(true));

    try {
      let res = await CreateApi.CreateApiGet(
        `api/v1/admin/manage/withdraw-pending?page=${page || 1}&limit=${
          limit || 10
        }${keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""}${
          symbol ? `&symbol=${encodeURIComponent(symbol)}` : ""
        }`,
        true
      );
      dispatch(GetAllWidthDrawCoin({ res: res?.data?.data, page: page }));
    } catch (error) {}
    dispatch(Loadding(false));
  };
};
export const HandleGetFee = (name) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/getfee",
        {
          name: name,
        },
        true
      );
      dispatch(GetFee(res?.data?.data));
    } catch (error) {}
  };
};

export const handleSubmit = (id, page) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/activeWidthdraw",
        {
          id,
        },
        true
      );
      toast.success(`🎉 Approve successful `);
    } catch (error) {
      ErrorMessage(error?.response?.data?.message);
    }
  };
};
export const handleSubmitCoin = (id, page, symbol, setOpenDetails) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/activeWidthdraw",
        {
          id,
        },
        true
      );
      toast.success(`🎉 Approve successful `);
      dispatch(HandleGetListCoin(page, symbol));
      if (setOpenDetails) setOpenDetails(false);
    } catch (error) {
      ErrorMessage(error?.response?.data?.message);
    }
  };
};
export const HandleCancelWidthDrawCoin = (id, note, page, symbol) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/cancelWidthdraw",
        {
          id: id,
          note: note,
        },
        true
      );
      dispatch(HandleGetListCoin(page, symbol));
      toast.success(`🎉 Cancel success `);
    } catch (error) {
      ErrorMessage(error?.response?.data?.message);
    }
  };
};
export const HandleCancelWidthDraw = (id, note, page) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/cancelWidthdraw",
        {
          id: id,
          note: note,
        },
        true
      );
      toast.success(`🎉 Cancel success `);
    } catch (error) {
      ErrorMessage(error?.response?.data?.message);
    }
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "widthdraw",
  initialState,
  reducers: {
    GetAllWidthDraw: function (state, action) {
      return {
        ...state,
        getallwidthdraw: action.payload?.res,
        getpagewithdraw: action.payload?.page,
      };
    },
    GetAllWidthDrawCoin: function (state, action) {
      return {
        ...state,
        getallWidthDrawCoin: action.payload?.res,
        getpagewithdrawcoin: action.payload?.page,
      };
    },
    GetFee: function (state, action) {
      return {
        ...state,
        getfee: action.payload,
      };
    },
  },
});

export default reducer;
export const WIDTHDRAW = TYPE;
export const { GetAllWidthDraw, GetFee, GetAllWidthDrawCoin } = action;
