import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";
import { Loadding } from "./isDifferentReducer";
import { ErrorMessage } from "../../core/message";

const initialState = {
  listkyc: "",
  pagelistkyc: 1,
  inputListKyc: "",
  listKycCancel: "",
  isGetListKycCancel: "",
  isApprovedKycCancel: "",
  isError: false,
};

export const GetUserKycCompleted = (page) => {
  return async (dispatch) => {
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApiS(
        `api/admin/getAllUsersKYC`,
        {
          limit: 10,
          page: page,
        },
        true
      );
      dispatch(GetAllUserKycCompleted({ res: res?.data?.data, page: page }));
    } catch (error) {}
    dispatch(Loadding(false));
  };
};
export const GetUserKycCompletedSearch = (page, keywork) => {
  return async (dispatch) => {
    var data = {
      limit: 10,
      page: page,
      username: keywork,
    };
    let res = await CreateApi.CreateApiS(
      "api/admin/getSearchUsersKYC",
      data,
      true
    );
    dispatch(
      GetAllUserKycCompleted({
        res: res?.data?.data,
        page: page,
        input: keywork,
      })
    );
    dispatch(Loadding(false));
  };
};

export const CancelUserKycCompleted = (userid, page, keywork) => {
  return async (dispatch) => {
    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/cancelUserKyc",
        { userid },
        true
      );
      toast(`🎉 ${res?.data?.message}`);
      dispatch(GetUserKycCompleted(page));
    } catch (error) {
      ErrorMessage(error?.response?.data?.message);
    }

    dispatch(Loadding(false));
  };
};

export const GetUserKycCancel = (page) => {
  return async (dispatch) => {
    dispatch(getListKycCancelStart());
    try {
      let res = await CreateApi.CreateApiS(
        `api/admin/getAllUsersKYCCancel`,
        {
          limit: 10,
          page: page,
        },
        true
      );
      dispatch(getListKycCancelSuccess({ res: res?.data?.data, page: page }));
    } catch (error) {}
    dispatch(getListKycCancelFail());
  };
};

export const GetUserKycCancelSearch = (page, keywork) => {
  return async (dispatch) => {
    var data = {
      limit: 10,
      page: page,
      username: keywork,
    };
    let res = await CreateApi.CreateApiS(
      "api/admin/getSearchUsersKYCCancel",
      data,
      true
    );
    dispatch(getListKycCancelSuccess({ res: res?.data?.data, page: page }));
    dispatch(Loadding(false));
  };
};

export const ApprovedUserKycCancel = (id) => {
  return async (dispatch) => {
    dispatch(approvedKycCancelStart());
    try {
      let res = await CreateApi.CreateApiS(
        `api/admin/activeUserKyc`,
        { userid: id },
        true
      );
      toast(`🎉 ${res?.data?.message}`);
      dispatch(GetUserKycCancel(1));
      dispatch(approvedKycCancelSuccess({ res: res?.data?.data }));
    } catch (error) {}
    dispatch(approvedKycCancelFail());
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "kycCompleted",
  initialState,
  reducers: {
    GetAllUserKycCompleted: (state, action) => {
      return {
        ...state,
        listKyc: action.payload?.res,
        pageListKyc: action.payload?.page,
        inputListKyc: action.payload?.input,
      };
    },
    getListKycCancelStart: (state) => {
      state.isGetListKycCancel = true;
      state.isError = initialState.isError;
    },
    getListKycCancelSuccess: (state, action) => {
      state.listKycCancel = action.payload;
      state.isGetListKycCancel = false;
      state.isError = initialState.isError;
    },
    getListKycCancelFail: (state, action) => {
      state.isError = action.payload;
      state.isGetListKycCancel = false;
    },
    approvedKycCancelStart: (state) => {
      state.isApprovedKycCancel = true;
      state.isError = initialState.isError;
    },
    approvedKycCancelSuccess: (state, action) => {
      state.listKycCancel = action.payload;
      state.isApprovedKycCancel = false;
      state.isError = initialState.isError;
    },
    approvedKycCancelFail: (state, action) => {
      state.isError = action.payload;
      state.isApprovedKycCancel = false;
    },
  },
});

export default reducer;
export const kycCompleted = TYPE;
export const {
  GetAllUserKycCompleted,
  getListKycCancelStart,
  getListKycCancelSuccess,
  getListKycCancelFail,
  approvedKycCancelStart,
  approvedKycCancelSuccess,
  approvedKycCancelFail,
} = action;
