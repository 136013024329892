
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import MainLayout2 from './MainLayout2';

export default function PrivateRoute2() {
    const user = JSON.parse(localStorage.getItem('auth'))
    // const { login } = useSelector(state => state.auth)
    if (!user) return <Navigate to='/login' />
    if (user?.id == 109 || user?.id == 111)
        return <MainLayout2 />

};