// export const port = "http://192.168.1.4:4056/";
// export const portStatistical = "http://192.168.1.4:5678/";
// export const portNode = "https://node.toearnnow.app/";
// export const portImg = `http://192.168.1.4:1111/`;
// export const portSocket = "wss://apinewwalletsds.toearnnow.com/";
// export const portNowMarket = "https://apinowmarketv2.toearnnow.com/";

// SAU ĐỔI
export const port = "https://apiadminsxz.toearnnow.live/";
export const portStatistical = "https://swzapinewwalletsds.toearnnow.com/";
export const portNode = "https://node.toearnnow.app/";
export const portImg = `https://apinewnew.toearnnow.com/`;
export const portSocket = "wss://swzapinewwalletsds.toearnnow.com/";
export const portNowMarket = "https://swzapinowmarketv2.toearnnow.com/";
