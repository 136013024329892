import {
  Box,
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import empty from "../../../asset/img/empty.png";
import CustomAutocomplete from "../../../components/RHFCom/CustomAutocomplete";
import { formatMoney, getStatusP2PColor } from "../../../core/convertNumber";
import {
  actionDetailP2P,
  actionGetCoinP2P,
  actionGetCurrencyP2P,
  actionGetListP2P,
  actionGetStatusP2P,
  actionUpdateStatustP2P,
} from "../../../redux/reducers/p2pReducer";
import DetailsP2P from "./DetailsP2P";
function ListDecidedP2P() {
  const {
    getCoinP2PData,
    getCoinP2PLoading,
    getCurrencyP2PData,
    getCurrencyP2PLoading,
    getStatusP2PData,
    getStatusP2PLoading,
    getListP2PData,
    getListP2PLoading,
  } = useSelector((state) => state.p2p);

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDetails, setOpenDetails] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    categories: "",
    countries: "",
    status: null,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleCloseDetails = () => setOpenDetails(false);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleOpenDetails = (id) => {
    setOpenDetails(true);
    dispatch(actionDetailP2P(id));
  };

  const handleUpdateStatus = (datas) => {
    dispatch(
      actionUpdateStatustP2P({ id: datas.id, status: datas.status })
    ).then((res) => {
      if (res?.status) {
        const data = {
          page: page,
          limit: rowsPerPage,
          coin: dataFilter?.coin,
          currency_id: dataFilter?.currency_id,
          status: "DECIDED",
        };
        dispatch(actionGetListP2P(data));
        handleCloseDetails();
      }
    });
  };

  useEffect(() => {
    dispatch(actionGetCoinP2P());
    dispatch(actionGetCurrencyP2P());
    dispatch(actionGetStatusP2P());
  }, []);

  useEffect(() => {
    const data = {
      page: page,
      limit: rowsPerPage,
      coin: dataFilter?.coin,
      currency_id: dataFilter?.currency_id,
      status: "DECIDED",
    };
    dispatch(actionGetListP2P(data));
  }, [page, rowsPerPage, dataFilter]);

  return (
    <>
      <Box sx={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <CustomAutocomplete
          fullWidth
          options={
            (getCoinP2PData?.length > 0 &&
              getCoinP2PData?.map((item) => {
                return {
                  label: item?.name,
                  value: item?.name,
                };
              })) ||
            []
          }
          renderInput={(params) => <TextField {...params} label="Coin" />}
          onChange={(_, option) => {
            setPage(1);
            setDataFilter({
              ...dataFilter,
              coin: option ? option.value : "",
            });
          }}
        />
        <CustomAutocomplete
          fullWidth
          options={
            (getCurrencyP2PData?.length > 0 &&
              getCurrencyP2PData?.map((item) => {
                return {
                  label: item?.currency_code,
                  value: item?.id,
                };
              })) ||
            []
          }
          renderInput={(params) => <TextField {...params} label="Currency" />}
          onChange={(_, option) => {
            setPage(1);
            setDataFilter({
              ...dataFilter,
              currency_id: option ? option.value : "",
            });
          }}
        />
      </Box>
      <TableContainer component={Paper} style={{ marginBottom: "0px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">STT</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">Currency</TableCell>
              <TableCell align="center">Fee</TableCell>
              <TableCell align="center">Buyer</TableCell>
              <TableCell align="center">Seller</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getListP2PLoading ||
            getCoinP2PLoading ||
            getCurrencyP2PLoading ||
            getStatusP2PLoading ? (
              Array.from({ length: 10 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>{" "}
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>{" "}
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>{" "}
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                </TableRow>
              ))
            ) : getListP2PData && getListP2PData?.count > 0 ? (
              getListP2PData?.rows?.map((e, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {i + 1 + (page - 1) * rowsPerPage}
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap variant="inherit">
                      {formatMoney(e?.amount)} {e?.init}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap variant="inherit">
                      {formatMoney(e?.price)} {e?.currency?.currency_code}
                      {"/"}
                      {e?.init}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap variant="inherit">
                      {formatMoney(e?.amount_currency)}{" "}
                      {e?.currency?.currency_code}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography noWrap variant="inherit">
                      {formatMoney(e?.fee)} {e?.init}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <Typography variant="inherit">
                        {e?.buyer?.username}
                      </Typography>
                      <Typography variant="inherit">
                        {e?.buyer?.email}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <Typography variant="inherit">
                        {e?.seller?.username}
                      </Typography>
                      <Typography variant="inherit">
                        {e?.seller?.email}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: "150px" }}>
                    {dayjs(e?.created_at).format("YYYY-MM-DD h:mm:ss A")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: getStatusP2PColor(e?.status) }}
                  >
                    {e?.status}{" "}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleOpenDetails(e?.id)}
                    >
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={empty}
                      style={{ maxWidth: "150px", margin: "20px 0" }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ paddingBottom: "20px", padding: "10px" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={getListP2PData?.count || 0}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <DetailsP2P
        openDetails={openDetails}
        handleCloseDetails={handleCloseDetails}
        handleUpdateStatus={handleUpdateStatus}
      />
    </>
  );
}

export default ListDecidedP2P;
