import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";

const initialState = {
  listGiftCardAdminData: null,
  listGiftCardAdminLoading: false,
  listGiftCardAdminError: "",

  listBuyGiftCardData: null,
  listBuyGiftCardLoading: false,
  listBuyGiftCardError: "",

  updateGiftCardAdminData: null,
  updateGiftCardAdminLoading: false,
  updateGiftCardAdminError: "",

  createGiftCardAdminData: null,
  createGiftCardAdminLoading: false,
  createGiftCardAdminError: "",

  deleteGiftCardAdminData: null,
  deleteGiftCardAdminLoading: false,
  deleteGiftCardAdminError: "",
};

export const actionGetGiftCardAdmin = (data, isLoading = true) => {
  return async (dispatch) => {
    dispatch(getListGiftCardAdminStart(isLoading));
    try {
      const { page, limit, keyword, currency, brand_id, status } = data;
      const apiEndpoint = `api/v1/admin/giftcard/list-admin?page=${
        page || 1
      }&limit=${limit || 10}${
        keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
      }${currency ? `&currency=${currency}` : ""}${
        brand_id ? `&brand_id=${brand_id}` : ""
      }${status ? `&status=${status}` : ""}`;

      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getListGiftCardAdminSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getListGiftCardAdminFail());
    }
  };
};

export const actionGetBuyGiftCard = (data, isLoading = true) => {
  return async (dispatch) => {
    dispatch(getListBuyGiftCardStart(isLoading));
    try {
      const { page, limit, keyword } = data;
      const apiEndpoint = `api/v1/admin/giftcard/list-user-buy?page=${
        page || 1
      }&limit=${limit || 10}${
        keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
      }`;
      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getListBuyGiftCardSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getListBuyGiftCardFail());
    }
  };
};

export const actionUpdateGiftCardAdmin = (data) => {
  return async (dispatch) => {
    dispatch(updateGiftCardAdminStart());
    const id = toast.loading("Please wait....");
    try {
      const apiEndpoint = `/api/v1/admin/giftcard/update/${data?.id}`;
      let res = await CreateApi.CreateApiPutSV2(apiEndpoint, data?.body, true);
      if (res.status === 200) {
        dispatch(updateGiftCardAdminSuccess(res?.data?.data));
        toast.update(id, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        return res?.data;
      } else {
        toast.update(id, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(updateGiftCardAdminFail());
      toast.update(id, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
};

export const actionCreateGiftCardAdmin = (data) => {
  return async (dispatch) => {
    dispatch(createGiftCardAdminStart());
    const id = toast.loading("Please wait....");
    try {
      const apiEndpoint = `api/v1/admin/giftcard/create-giftcard`;
      let res = await CreateApi.CreateApiSV2(apiEndpoint, data, true);
      if (res.status === 200) {
        dispatch(createGiftCardAdminSuccess(res?.data?.data));
        toast.update(id, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        return res?.data;
      } else {
        toast.update(id, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(createGiftCardAdminFail());
      toast.update(id, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
};

export const actionDeleteGiftCardAdmin = (ids) => {
  return async (dispatch) => {
    dispatch(deleteGiftCardAdminStart());
    const id = toast.loading("Please wait....");
    try {
      const apiEndpoint = `api/v1/admin/giftcard/delete-admin/${ids}`;
      let res = await CreateApi.CreateApiDeleteSV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(deleteGiftCardAdminSuccess(res?.data?.data));
        toast.update(id, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        return res?.data;
      } else {
        toast.update(id, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(deleteGiftCardAdminFail());
      toast.update(id, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "giftCardSystem",
  initialState,
  reducers: {
    getListGiftCardAdminStart: (state, isLoading) => {
      state.listGiftCardAdminLoading = isLoading.payload;
    },
    getListGiftCardAdminSuccess: (state, action) => {
      state.listGiftCardAdminLoading = false;
      state.listGiftCardAdminData = action.payload;
      state.listGiftCardAdminError = "";
    },
    getListGiftCardAdminFail: (state, action) => {
      state.listGiftCardAdminLoading = false;
      state.listGiftCardAdminError = action.payload;
      state.listGiftCardAdminData = {};
    },

    getListBuyGiftCardStart: (state, isLoading) => {
      state.listBuyGiftCardLoading = isLoading.payload;
    },
    getListBuyGiftCardSuccess: (state, action) => {
      state.listBuyGiftCardLoading = false;
      state.listBuyGiftCardData = action.payload;
      state.listBuyGiftCardError = "";
    },
    getListBuyGiftCardFail: (state, action) => {
      state.listBuyGiftCardLoading = false;
      state.listBuyGiftCardError = action.payload;
      state.listBuyGiftCardData = {};
    },

    updateGiftCardAdminStart: (state) => {
      state.updateGiftCardAdminLoading = true;
    },
    updateGiftCardAdminSuccess: (state, action) => {
      state.updateGiftCardAdminLoading = false;
      state.updateGiftCardAdminData = action.payload;
      state.updateGiftCardAdminError = "";
    },
    updateGiftCardAdminFail: (state, action) => {
      state.updateGiftCardAdminLoading = false;
      state.updateGiftCardAdminError = action.payload;
      state.updateGiftCardAdminData = {};
    },

    createGiftCardAdminStart: (state) => {
      state.createGiftCardAdminLoading = true;
    },
    createGiftCardAdminSuccess: (state, action) => {
      state.createGiftCardAdminLoading = false;
      state.createGiftCardAdminData = action.payload;
      state.createGiftCardAdminError = "";
    },
    createGiftCardAdminFail: (state, action) => {
      state.createGiftCardAdminLoading = false;
      state.createGiftCardAdminError = action.payload;
      state.createGiftCardAdminData = {};
    },

    deleteGiftCardAdminStart: (state) => {
      state.deleteGiftCardAdminLoading = true;
    },
    deleteGiftCardAdminSuccess: (state, action) => {
      state.deleteGiftCardAdminLoading = false;
      state.deleteGiftCardAdminData = action.payload;
      state.deleteGiftCardAdminError = "";
    },
    deleteGiftCardAdminFail: (state, action) => {
      state.deleteGiftCardAdminLoading = false;
      state.deleteGiftCardAdminError = action.payload;
      state.deleteGiftCardAdminData = {};
    },
  },
});

export default reducer;
export const brand = TYPE;
export const {
  getListGiftCardAdminStart,
  getListGiftCardAdminSuccess,
  getListGiftCardAdminFail,
  getListBuyGiftCardStart,
  getListBuyGiftCardSuccess,
  getListBuyGiftCardFail,
  updateGiftCardAdminStart,
  updateGiftCardAdminSuccess,
  updateGiftCardAdminFail,
  createGiftCardAdminStart,
  createGiftCardAdminSuccess,
  createGiftCardAdminFail,
  deleteGiftCardAdminStart,
  deleteGiftCardAdminSuccess,
  deleteGiftCardAdminFail,
} = action;
