import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";
import { Loadding } from "./isDifferentReducer";

const initialState = {
  getListCoinWidthdraw: "",
  getPageListCoinWithdraw: 1,
};

export const HandleGetListCoinWidthdraw = (data) => {
  return async (dispatch) => {
    const { page, limit, keyword = "" } = data;
    dispatch(Loadding(true));
    try {
      let res = await CreateApi.CreateApiGet(
        `api/admin/getListWidthdrawCoinAll?page=${page || 1}&limit=${
          limit || 10
        }${keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""}`,
        true
      );
      dispatch(GetListCoinWidthdraw({ res: res?.data?.data, page: page }));
    } catch (error) {}
    dispatch(Loadding(false));
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "getlist",
  initialState,
  reducers: {
    GetListCoinWidthdraw: function (state, action) {
      return {
        ...state,
        getListCoinWidthdraw: action.payload?.res,
        getPageListCoinWithdraw: action.payload?.page,
      };
    },
  },
});

export default reducer;
export const GETLIST = TYPE;
export const { GetListCoinWidthdraw } = action;
