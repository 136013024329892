import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";
import socket from "../../socket";

const initialState = {
  getCoinP2PData: null,
  getCoinP2PLoading: false,
  getCoinP2PError: "",

  getCurrencyP2PData: null,
  getCurrencyP2PLoading: false,
  getCurrencyP2PError: "",

  getStatusP2PData: null,
  getStatusP2PLoading: false,
  getStatusP2PError: "",

  getListP2PData: null,
  getListP2PLoading: false,
  getListP2PError: "",

  getDetailP2PData: null,
  getDetailP2PLoading: false,
  getDetailP2PError: "",

  updateStatusP2PData: null,
  updateStatusP2PLoading: false,
  updateStatusP2PError: "",
};

export const handleGetSocketChatBot = ({ p2p, mess, images }) => {
  return async (dispatch, getState) => {
    try {
      socket.emit("push_mes", {
        p2p_id: p2p,
        message: mess,
        images,
      });
    } catch (error) {}
  };
};

export const actionGetCoinP2P = () => {
  return async (dispatch) => {
    dispatch(getCoinP2PStart());
    try {
      let res = await CreateApi.CreateApiGetV2(
        "api/v1/common/master/coin-p2p",
        true
      );
      if (res.status === 200) {
        dispatch(getCoinP2PSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getCoinP2PFail());
    }
  };
};

export const actionGetCurrencyP2P = (data) => {
  return async (dispatch) => {
    dispatch(getCurrencyP2PStart());
    try {
      let res = await CreateApi.CreateApiGetV2(
        "api/v1/common/master/currency",
        true
      );
      if (res.status === 200) {
        dispatch(getCurrencyP2PSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getCurrencyP2PFail());
    }
  };
};

export const actionGetStatusP2P = (data) => {
  return async (dispatch) => {
    dispatch(getStatusP2PStart());
    try {
      let res = await CreateApi.CreateApiGetV2(
        "api/v1/common/master/status-p2p",
        true
      );
      if (res.status === 200) {
        dispatch(getStatusP2PSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getStatusP2PFail());
    }
  };
};

export const actionGetListP2P = (data, isLoading = true) => {
  return async (dispatch) => {
    dispatch(getListP2PStart(isLoading));
    try {
      const { page, limit, coin, currency_id, status } = data;
      const apiEndpoint = `api/v1/admin/p2p/list?page=${page || 1}&limit=${
        limit || 10
      }${coin ? `&coin=${encodeURIComponent(coin)}` : ""}
      ${currency_id ? `&currency_id=${currency_id}` : ""}${
        status ? `&status=${status}` : ""
      }`;

      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getListP2PSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getListP2PFail());
    }
  };
};

export const actionDetailP2P = (data) => {
  return async (dispatch) => {
    dispatch(getDetailP2PStart());
    try {
      const apiEndpoint = `api/v1/admin/p2p/detail/${data}`;
      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getDetailP2PSuccess(res?.data?.data));
        return res?.data;
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getDetailP2PFail());
    }
  };
};

export const actionUpdateStatustP2P = (data) => {
  return async (dispatch) => {
    dispatch(updateStatusP2PStart());
    const id = toast.loading("Please wait....");
    try {
      const apiEndpoint = `api/v1/admin/p2p/update-status/${data?.id}`;
      let res = await CreateApi.CreateApiSV2(
        apiEndpoint,
        { status: data?.status },
        true
      );
      if (res.status === 200) {
        dispatch(updateStatusP2PSuccess(res?.data?.data));
        toast.update(id, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        return res?.data;
      } else {
        toast.update(id, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(updateStatusP2PFail());
      toast.update(id, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "p2p",
  initialState,
  reducers: {
    getCoinP2PStart: (state) => {
      state.getCoinP2PLoading = true;
    },
    getCoinP2PSuccess: (state, action) => {
      state.getCoinP2PLoading = false;
      state.getCoinP2PData = action.payload;
      state.getCoinP2PError = "";
    },
    getCoinP2PFail: (state, action) => {
      state.getCoinP2PLoading = false;
      state.getCoinP2PError = action.payload;
      state.getCoinP2PData = {};
    },

    getCurrencyP2PStart: (state) => {
      state.getCurrencyP2PLoading = true;
    },
    getCurrencyP2PSuccess: (state, action) => {
      state.getCurrencyP2PLoading = false;
      state.getCurrencyP2PData = action.payload;
      state.getCurrencyP2PError = "";
    },
    getCurrencyP2PFail: (state, action) => {
      state.getCurrencyP2PLoading = false;
      state.getCurrencyP2PError = action.payload;
      state.getCurrencyP2PData = {};
    },

    getStatusP2PStart: (state) => {
      state.getStatusP2PLoading = true;
    },
    getStatusP2PSuccess: (state, action) => {
      state.getStatusP2PLoading = false;
      state.getStatusP2PData = action.payload;
      state.getStatusP2PError = "";
    },
    getStatusP2PFail: (state, action) => {
      state.getStatusP2PLoading = false;
      state.getStatusP2PError = action.payload;
      state.getStatusP2PData = {};
    },

    getListP2PStart: (state, isLoading) => {
      state.getListP2PLoading = isLoading.payload;
    },
    getListP2PSuccess: (state, action) => {
      state.getListP2PLoading = false;
      state.getListP2PData = action.payload;
      state.getListP2PError = "";
    },
    getListP2PFail: (state, action) => {
      state.getListP2PLoading = false;
      state.getListP2PError = action.payload;
      state.getListP2PData = {};
    },

    getDetailP2PStart: (state) => {
      state.getDetailP2PLoading = true;
    },
    getDetailP2PSuccess: (state, action) => {
      state.getDetailP2PLoading = false;
      state.getDetailP2PData = action.payload;
      state.getDetailP2PError = "";
    },
    getDetailP2PFail: (state, action) => {
      state.getDetailP2PLoading = false;
      state.getDetailP2PError = action.payload;
      state.getDetailP2PData = {};
    },

    updateStatusP2PStart: (state) => {
      state.updateStatusP2PLoading = true;
    },
    updateStatusP2PSuccess: (state, action) => {
      state.updateStatusP2PLoading = false;
      state.updateStatusP2PData = action.payload;
      state.updateStatusP2PError = "";
    },
    updateStatusP2PFail: (state, action) => {
      state.updateStatusP2PLoading = false;
      state.updateStatusP2PError = action.payload;
      state.updateStatusP2PData = {};
    },
  },
});

export default reducer;
export const p2p = TYPE;
export const {
  getCoinP2PStart,
  getCoinP2PSuccess,
  getCoinP2PFail,
  getCurrencyP2PStart,
  getCurrencyP2PSuccess,
  getCurrencyP2PFail,
  getStatusP2PStart,
  getStatusP2PSuccess,
  getStatusP2PFail,
  getListP2PStart,
  getListP2PSuccess,
  getListP2PFail,
  getDetailP2PStart,
  getDetailP2PSuccess,
  getDetailP2PFail,
  updateStatusP2PStart,
  updateStatusP2PSuccess,
  updateStatusP2PFail,
} = action;
