import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useDebounce } from "use-debounce";
import empty from "../../../asset/img/empty.png";
import CustomAutocomplete from "../../../components/RHFCom/CustomAutocomplete";
import {
  actionFilterGiftCardPartner,
  actionGetGiftCardPartner,
  actionUpdateFeetGiftCardPartner,
  actionUpdateStatustGiftCardPartner,
} from "../../../redux/reducers/giftCardReducer";
import {
  SCHEMA_UPDATE_FEE,
  schemaModalEditCoin,
} from "../../admin/components/validate";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
function ListGiftCard() {
  const {
    filterGiftCardLoading,
    filterGiftCardData,
    getListGiftCardData,
    getListGiftCardLoading,
  } = useSelector((state) => state.giftCard);

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [giftDetail, setGiftDetail] = useState({
    modal: false,
    detail: null,
  });

  const [dataFilter, setDataFilter] = useState({
    keyword: "",
    categories: "",
    countries: "",
    active: null,
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SCHEMA_UPDATE_FEE),
  });

  const [keywordSearch] = useDebounce(dataFilter?.keyword, 1000);
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const handleSearch = (event) => {
    setDataFilter({ ...dataFilter, keyword: event.target?.value });
  };

  const handleChangeActive = (id, active) => {
    dispatch(
      actionUpdateStatustGiftCardPartner({ id: id, active: active })
    ).then((res) => {
      if (res?.status)
        dispatch(
          actionGetGiftCardPartner(
            {
              page: page,
              limit: rowsPerPage,
              keyword: keywordSearch,
              countries: dataFilter?.countries,
              categories: dataFilter?.categories,
              active: dataFilter?.active === "" ? "" : dataFilter?.active,
            },
            false
          )
        );
    });
  };

  const handleClose = () => setGiftDetail({ modal: false, detail: null });

  const handleChooseGift = (gift) => {
    setGiftDetail({ modal: true, detail: gift });
    setValue("fee", gift?.additional_fee);
  };

  const dataFilterAPI = {
    page: page,
    limit: rowsPerPage,
    keyword: keywordSearch,
    countries: dataFilter?.countries,
    categories: dataFilter?.categories,
    active: dataFilter?.active === "" ? "" : dataFilter?.active,
  };

  const onSubmit = (data) => {
    dispatch(
      actionUpdateFeetGiftCardPartner({
        id: giftDetail?.detail?.productId,
        fee: data?.fee,
      })
    ).then((res) => {
      if (res.status) {
        dispatch(actionGetGiftCardPartner(dataFilterAPI));
        handleClose();
        reset();
      }
    });
  };
  useEffect(() => {
    dispatch(actionFilterGiftCardPartner());
  }, []);

  useEffect(() => {
    dispatch(actionGetGiftCardPartner(dataFilterAPI));
  }, [page, rowsPerPage, keywordSearch, dataFilter]);

  return (
    <>
      <Box sx={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Keyword"
          variant="outlined"
          onChange={handleSearch}
        />
        <CustomAutocomplete
          fullWidth
          options={
            filterGiftCardData?.countries?.map((item) => {
              return {
                label: item?.name,
                value: item?.value,
              };
            }) || []
          }
          renderInput={(params) => <TextField {...params} label="Countries" />}
          onChange={(_, option) => {
            setPage(1);
            setDataFilter({
              ...dataFilter,
              countries: option ? option.value : "",
            });
          }}
        />
        <CustomAutocomplete
          fullWidth
          options={
            filterGiftCardData?.categories?.map((item) => {
              return {
                label: item?.name,
                value: item?.value,
              };
            }) || []
          }
          renderInput={(params) => <TextField {...params} label="Categories" />}
          onChange={(_, option) => {
            setPage(1);
            setDataFilter({
              ...dataFilter,
              categories: option ? option.value : "",
            });
          }}
        />

        <CustomAutocomplete
          fullWidth
          options={[
            {
              label: "TRUE",
              value: "true",
            },
            {
              label: "FALSE",
              value: "false",
            },
          ]}
          renderInput={(params) => <TextField {...params} label="Active" />}
          onChange={(_, option) => {
            setPage(1);
            setDataFilter({
              ...dataFilter,
              active: option ? option.value : "",
            });
          }}
        />
      </Box>
      <TableContainer component={Paper} style={{ marginBottom: "0px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">STT</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Country</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">
                <Typography noWrap sx={{ fontWeight: "500", fontSize: "14px" }}>
                  Additional Fee
                </Typography>
              </TableCell>
              <TableCell align="center">Fee</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getListGiftCardLoading || filterGiftCardLoading ? (
              Array.from({ length: 10 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>{" "}
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>{" "}
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>{" "}
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={45} />
                  </TableCell>
                </TableRow>
              ))
            ) : getListGiftCardData && getListGiftCardData?.count > 0 ? (
              getListGiftCardData?.rows?.map((e, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {i + 1 + (page - 1) * rowsPerPage}
                  </TableCell>
                  <TableCell align="center">
                    <img
                      style={{ width: "80px" }}
                      src={e?.imageUrl}
                      alt={e?.name}
                      loading="lazy"
                    />
                  </TableCell>
                  <TableCell align="center">{e?.name} </TableCell>
                  <TableCell align="center">
                    {e?.countryName} ({e?.countryCode}){" "}
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ maxWidth: "300px" }}>
                      {e?.categories}{" "}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">{e?.additional_fee}% </TableCell>
                  <TableCell align="center">{e?.fee}% </TableCell>
                  <TableCell align="center">
                    {" "}
                    <Switch
                      checked={e?.active}
                      onChange={() =>
                        handleChangeActive(e?.productId, !e?.active)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />{" "}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={() => handleChooseGift(e)}
                    >
                      <Typography
                        noWrap
                        sx={{ fontWeight: "500", fontSize: "14px" }}
                      >
                        Update Fee
                      </Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={empty}
                      style={{ maxWidth: "150px", margin: "20px 0" }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ paddingBottom: "20px", padding: "10px" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={getListGiftCardData?.count || 0}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Dialog maxWidth="md" open={giftDetail.modal} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            dividers
            sx={{ paddingY: 2, paddingX: 3, width: "100%", minWidth: "500px" }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Update Additional Fee
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: "10px" }}>
              Gift card name : {giftDetail.detail?.name}
            </Typography>
            <Controller
              name="fee"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Additional Fee"
                  {...field}
                  helperText={errors.fee?.message?.toString() ?? ""}
                  error={errors.fee ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="error">
              Close
            </Button>
            <Button type="submit" variant="contained">
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ListGiftCard;
