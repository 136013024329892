import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";

const initialState = {
  listDepositMasterCard: "",
  isListDepositMasterCard: "",
  updateDepositMasterCard: "",
  isUpdateDepositMasterCard: "",
};

export const UpdateDepositMasterCard = ({ id, data }) => {
  return async (dispatch) => {
    dispatch(updateDepositMasterCardStart());
    try {
      let res = await CreateApi.CreateApiSV2(
        `api/v1/admin/master-card/update-status/${id}`,
        data,
        true
      );
      if (res.status === 200) {
        toast.success(`🎉 ${res?.data?.message} `);
        dispatch(updateDepositMasterCardSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      toast.error(message);
    }
    dispatch(updateDepositMasterCardFail());
  };
};

export const GetListDepositMasterCard = (data) => {
  return async (dispatch) => {
    const { page, limit, keyword = "", status = "" } = data;
    dispatch(getListDepositMasterCardStart());
    try {
      let res = await CreateApi.CreateApiGetV2(
        `api/v1/admin/master-card/list?page=${page || 1}&limit=${limit || 10}${
          keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
        }${status ? `&status=${encodeURIComponent(status)}` : ""}`,
        true
      );
      dispatch(getListDepositMasterCardSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getListDepositMasterCardFail());
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "masterCard",
  initialState,
  reducers: {
    updateDepositMasterCardStart: (state) => {
      state.isUpdateDepositMasterCard = true;
      state.isError = initialState.isError;
    },
    updateDepositMasterCardSuccess: (state, action) => {
      state.updateDepositMasterCard = action.payload;
      state.isUpdateDepositMasterCard = false;
      state.isError = initialState.isError;
    },
    updateDepositMasterCardFail: (state, action) => {
      state.isError = action.payload;
      state.isUpdateDepositMasterCard = false;
    },

    getListDepositMasterCardStart: (state) => {
      state.isListDepositMasterCard = true;
      state.isError = initialState.isError;
    },
    getListDepositMasterCardSuccess: (state, action) => {
      state.listDepositMasterCard = action.payload;
      state.isListDepositMasterCard = false;
      state.isError = initialState.isError;
    },
    getListDepositMasterCardFail: (state, action) => {
      state.isError = action.payload;
      state.isListDepositMasterCard = false;
    },
  },
});

export default reducer;
export const MASTERCARD = TYPE;
export const {
  getListDepositMasterCardStart,
  getListDepositMasterCardSuccess,
  getListDepositMasterCardFail,
  updateDepositMasterCardStart,
  updateDepositMasterCardSuccess,
  updateDepositMasterCardFail,
} = action;
