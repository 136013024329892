import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";

const initialState = {
  getListBrandData: null,
  getListBrandLoading: false,
  getListBrandError: "",

  updateBrandData: null,
  updateBrandLoading: false,
  updateBrandError: "",

  detailBrandData: null,
  detailBrandLoading: false,
  detailBrandError: "",

  createBrandData: null,
  createBrandLoading: false,
  createBrandError: "",

  deleteBrandData: null,
  deleteBrandLoading: false,
  deleteBrandError: "",

  getListCountryData: null,
  getListCountryLoading: false,
  getListCountryError: "",

  getListPlaceData: null,
  getListPlaceLoading: false,
  getListPlaceError: "",

  uploadFileData: null,
  uploadFileLoading: false,
  uploadFileError: "",
};

export const actionGetListCountry = (isLoading = true) => {
  return async (dispatch) => {
    dispatch(getListCountryStart(isLoading));
    try {
      const apiEndpoint = `api/v1/user/location/country`;
      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getListCountrySuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getListCountryFail());
    }
  };
};

export const actionGetListPlace = (isLoading = true) => {
  return async (dispatch) => {
    dispatch(getListPlaceStart(isLoading));
    try {
      const apiEndpoint = `api/v1/user/location/type-place`;

      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getListPlaceSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getListPlaceFail());
    }
  };
};

export const actionGetListBrand = (data, isLoading = true) => {
  return async (dispatch) => {
    dispatch(getListBrandStart(isLoading));
    try {
      const { page, limit, keyword } = data;
      const apiEndpoint = `api/v1/admin/giftcard/list-brand?page=${
        page || 1
      }&limit=${limit || 10}${
        keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
      }
      `;

      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getListBrandSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getListBrandFail());
    }
  };
};

export const actionDetailBrand = (data) => {
  return async (dispatch) => {
    dispatch(detailBrandStart());
    try {
      const apiEndpoint = `api/v1/admin/giftcard/detail-brand/${data}`;
      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(detailBrandSuccess(res?.data?.data));
        return res?.data;
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(detailBrandFail());
    }
  };
};

export const actionUpdateBrand = (data) => {
  return async (dispatch) => {
    dispatch(updateBrandStart());
    const id = toast.loading("Please wait....");
    try {
      const apiEndpoint = `api/v1/admin/giftcard/update-brand`;
      let res = await CreateApi.CreateApiPutSV2(apiEndpoint, data, true);
      if (res.status === 200) {
        dispatch(updateBrandSuccess(res?.data?.data));
        toast.update(id, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        return res?.data;
      } else {
        toast.update(id, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(updateBrandFail());
      toast.update(id, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
};

export const actionCreateBrand = (data) => {
  return async (dispatch) => {
    dispatch(createBrandStart());
    const id = toast.loading("Please wait....");
    try {
      const apiEndpoint = `api/v1/admin/giftcard/create-brand`;
      let res = await CreateApi.CreateApiSV2(apiEndpoint, data, true);
      if (res.status === 200) {
        dispatch(createBrandSuccess(res?.data?.data));
        toast.update(id, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        return res?.data;
      } else {
        toast.update(id, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(createBrandFail());
      toast.update(id, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
};

export const actionDeleteBrand = (ids) => {
  return async (dispatch) => {
    dispatch(createBrandStart());
    const id = toast.loading("Please wait....");
    try {
      const apiEndpoint = `api/v1/admin/giftcard/delete-brand/${ids}`;
      let res = await CreateApi.CreateApiDeleteSV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(createBrandSuccess(res?.data?.data));
        toast.update(id, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        return res?.data;
      } else {
        toast.update(id, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(createBrandFail());
      toast.update(id, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
};

export const actionUploadBulkImage = (data) => {
  return async (dispatch) => {
    dispatch(uploadFileStart());
    const ids = toast.loading("Please wait....");
    try {
      const apiEndpoint = `api/v1/file/bulk-upload`;
      let res = await CreateApi.CreateApiUploadSV2(apiEndpoint, data, true);
      if (res.status === 200) {
        dispatch(uploadFileSuccess(res?.data?.data));
        toast.update(ids, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        return res?.data;
      } else {
        toast.update(ids, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(uploadFileFail());
      toast.update(ids, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
};

export const actionUploadImage = (data) => {
  return async (dispatch) => {
    dispatch(uploadFileStart());
    const ids = toast.loading("Please wait....");
    try {
      const apiEndpoint = `api/v1/file/single-upload`;
      let res = await CreateApi.CreateApiUploadSV2(apiEndpoint, data, true);
      if (res.status === 200) {
        dispatch(uploadFileSuccess(res?.data?.data));
        toast.update(ids, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        return res?.data;
      } else {
        toast.update(ids, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(uploadFileFail());
      toast.update(ids, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "brand",
  initialState,
  reducers: {
    getListCountryStart: (state, isLoading) => {
      state.getListCountryLoading = isLoading.payload;
    },
    getListCountrySuccess: (state, action) => {
      state.getListCountryLoading = false;
      state.getListCountryData = action.payload;
      state.getListCountryError = "";
    },
    getListCountryFail: (state, action) => {
      state.getListCountryLoading = false;
      state.getListCountryError = action.payload;
      state.getListCountryData = {};
    },

    getListPlaceStart: (state, isLoading) => {
      state.getListPlaceLoading = isLoading.payload;
    },
    getListPlaceSuccess: (state, action) => {
      state.getListPlaceLoading = false;
      state.getListPlaceData = action.payload;
      state.getListPlaceError = "";
    },
    getListPlaceFail: (state, action) => {
      state.getListPlaceLoading = false;
      state.getListPlaceError = action.payload;
      state.getListPlaceData = {};
    },

    getListBrandStart: (state, isLoading) => {
      state.getListBrandLoading = isLoading.payload;
    },
    getListBrandSuccess: (state, action) => {
      state.getListBrandLoading = false;
      state.getListBrandData = action.payload;
      state.getListBrandError = "";
    },
    getListBrandFail: (state, action) => {
      state.getListBrandLoading = false;
      state.getListBrandError = action.payload;
      state.getListBrandData = {};
    },

    detailBrandStart: (state) => {
      state.detailBrandLoading = true;
    },
    detailBrandSuccess: (state, action) => {
      state.detailBrandLoading = false;
      state.detailBrandData = action.payload;
      state.detailBrandError = "";
    },
    detailBrandFail: (state, action) => {
      state.detailBrandLoading = false;
      state.detailBrandError = action.payload;
      state.detailBrandData = {};
    },

    updateBrandStart: (state) => {
      state.updateBrandLoading = true;
    },
    updateBrandSuccess: (state, action) => {
      state.updateBrandLoading = false;
      state.updateBrandData = action.payload;
      state.updateBrandError = "";
    },
    updateBrandFail: (state, action) => {
      state.updateBrandLoading = false;
      state.updateBrandError = action.payload;
      state.updateBrandData = {};
    },

    createBrandStart: (state) => {
      state.createBrandLoading = true;
    },
    createBrandSuccess: (state, action) => {
      state.createBrandLoading = false;
      state.createBrandData = action.payload;
      state.createBrandError = "";
    },
    createBrandFail: (state, action) => {
      state.createBrandLoading = false;
      state.createBrandError = action.payload;
      state.createBrandData = {};
    },

    deleteBrandStart: (state) => {
      state.deleteBrandLoading = true;
    },
    deleteBrandSuccess: (state, action) => {
      state.deleteBrandLoading = false;
      state.deleteBrandData = action.payload;
      state.deleteBrandError = "";
    },
    deleteBrandFail: (state, action) => {
      state.deleteBrandLoading = false;
      state.deleteBrandError = action.payload;
      state.deleteBrandData = {};
    },

    uploadFileStart: (state) => {
      state.uploadFileLoading = true;
    },
    uploadFileSuccess: (state, action) => {
      state.uploadFileLoading = false;
      state.uploadFileData = action.payload;
      state.uploadFileError = "";
    },
    uploadFileFail: (state, action) => {
      state.uploadFileLoading = false;
      state.uploadFileError = action.payload;
      state.uploadFileData = {};
    },
  },
});

export default reducer;
export const brand = TYPE;
export const {
  getListBrandStart,
  getListBrandSuccess,
  getListBrandFail,
  detailBrandStart,
  detailBrandSuccess,
  detailBrandFail,
  updateBrandStart,
  updateBrandSuccess,
  updateBrandFail,
  createBrandStart,
  createBrandSuccess,
  createBrandFail,
  deleteBrandStart,
  deleteBrandSuccess,
  deleteBrandFail,
  getListCountryStart,
  getListCountrySuccess,
  getListCountryFail,
  getListPlaceStart,
  getListPlaceSuccess,
  getListPlaceFail,
  uploadFileStart,
  uploadFileSuccess,
  uploadFileFail,
} = action;
