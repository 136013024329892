import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";

const initialState = {
  getListVoucherData: null,
  getListVoucherLoading: false,
  getListVoucherError: "",

  getListUserVoucherData: null,
  getListUserVoucherLoading: false,
  getListVoucherError: "",
};

export const actionGetListVoucher = (data, isLoading = true) => {
  return async (dispatch) => {
    dispatch(getListVoucherStart(isLoading));
    try {
      const { page, limit, keyword, status } = data;
      const apiEndpoint = `api/v1/admin/voucher/list?page=${page || 1}&limit=${
        limit || 10
      }${keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""}${
        status ? `&status=${encodeURIComponent(status)}` : ""
      }
    `;
      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getListVoucherSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getListVoucherFail());
    }
  };
};

export const actionGetListUserVoucher = (data, isLoading = true) => {
  return async (dispatch) => {
    dispatch(getListUserVoucherStart(isLoading));
    try {
      const { page, limit, keyword, status } = data;
      const apiEndpoint = `api/v1/admin/voucher/list-user-buy?page=${
        page || 1
      }&limit=${limit || 10}${
        keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
      }${status ? `&status=${encodeURIComponent(status)}` : ""}
    `;
      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getListUserVoucherSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getListUserVoucherFail());
    }
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "voucher",
  initialState,
  reducers: {
    getListVoucherStart: (state, isLoading) => {
      state.getListVoucherLoading = isLoading.payload;
    },
    getListVoucherSuccess: (state, action) => {
      state.getListVoucherLoading = false;
      state.getListVoucherData = action.payload;
      state.getListVoucherError = "";
    },
    getListVoucherFail: (state, action) => {
      state.getListVoucherLoading = false;
      state.getListVoucherError = action.payload;
      state.getListVoucherData = {};
    },

    getListUserVoucherStart: (state, isLoading) => {
      state.getListUserVoucherLoading = isLoading.payload;
    },
    getListUserVoucherSuccess: (state, action) => {
      state.getListUserVoucherLoading = false;
      state.getListUserVoucherData = action.payload;
      state.getListUserVoucherError = "";
    },
    getListUserVoucherFail: (state, action) => {
      state.getListUserVoucherLoading = false;
      state.getListUserVoucherError = action.payload;
      state.getListUserVoucherData = {};
    },
  },
});

export default reducer;
export const voucher = TYPE;
export const {
  getListVoucherStart,
  getListVoucherSuccess,
  getListVoucherFail,
  getListUserVoucherStart,
  getListUserVoucherSuccess,
  getListUserVoucherFail,
} = action;
