import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import style from "../asset/style.scss";
// import SildeBar from '../components/SildeBar';
import Sildebar2 from "../components/Sildebar2";

import Header from "../components/Header";
import Loadding from "../components/Loadding";
import ShadowLayer from "../components/ShadowLayer";
import { ToastContainer } from "react-toastify";
export default function MainLayout2() {
  const [sidebar, SetSidebar] = useState(false);

  return (
    <>
      {/* <SildeBar /> */}
      <Sildebar2 SetSidebar={SetSidebar} sidebar={sidebar} />
      <div className="home">
        <Outlet />
      </div>

      <Loadding />
      <ShadowLayer />
    </>
  );
}
