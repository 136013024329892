import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatMoney } from "../../../core/convertNumber";
import { portImg } from "../../../port";
import socket from "../../../socket";
import Chat from "./Chat";
function DetailsP2P({ openDetails, handleCloseDetails, handleUpdateStatus }) {
  const { getDetailP2PData: data, getDetailP2PLoading } = useSelector(
    (state) => state.p2p
  );
  const [dataChat, setDataChat] = useState([]);
  const imgComplain = data && data?.complain?.images;
  const user = JSON.parse(localStorage.getItem("auth"));

  const handleUpdateStatusP2P = (data) => {
    handleUpdateStatus(data);
  };

  useEffect(() => {
    if (data && openDetails && !getDetailP2PLoading) {
      setDataChat(data?.chat || []);
      socket.connect();
      user?.token && socket.emit("authenticate", user?.token);
      data?.id &&
        socket.emit("get_mes", {
          p2p_id: data?.id,
        });

      socket.on("get_mes", (result) => {
        if (result) {
          setDataChat((prevDataChat) => [...prevDataChat, result]);
        }
      });
    }

    return () => {
      socket.off("get_mes");
      socket.off("push_mes");
      // setTimeout(() => {
      socket.disconnect();
      // }, 200);
    };
  }, [openDetails, data, getDetailP2PLoading]);

  // useEffect(() => {
  //   user && socket.connect();
  //   setTimeout(() => {
  //     user?.token &&
  //   }, 300);
  // }, [user]);
  // console.log(data, "data");

  return (
    <Dialog maxWidth="lg" open={openDetails} onClose={handleCloseDetails}>
      <DialogTitle>Detail Giftcard</DialogTitle>
      <DialogContent dividers sx={{ p: 3, width: "100%" }}>
        {getDetailP2PLoading ? (
          <Grid sx={{ width: "1150px" }} container spacing={3}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                marginBottom: "15px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Skeleton animation="wave" width={300} height={200} />
            </Box>
            <Grid xs={12} md={6} item>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={12} item>
              <Skeleton animation="wave" height={300} />
            </Grid>
            <Grid xs={12} md={12} item>
              <Skeleton animation="wave" height={300} />
            </Grid>
          </Grid>
        ) : (
          <Box>
            <Grid container spacing={3}>
              {" "}
              <Grid xs={12} md={6} item>
                <Typography noWrap variant="h5">
                  Seller
                </Typography>
                <Grid container spacing={3} sx={{ marginTop: "10px" }}>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Username"
                      value={data?.seller?.username}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Email"
                      value={data?.seller?.email}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Seller Bank Owner"
                      value={data?.seller_bank?.owner_banking}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Seller Bank Number"
                      value={data?.seller_bank?.number_banking}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Seller Bank Name"
                      value={data?.seller_bank?.name_banking}
                    />
                  </Grid>
                  {data?.complain?.type === "SELLER" && (
                    <Grid xs={12} md={12} item>
                      <Accordion>
                        <AccordionSummary
                          sx={{ minHeight: "55px" }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>Complain</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={3}>
                            <Grid xs={12} md={12} item>
                              <TextField
                                shrink
                                fullWidth
                                label="Created At"
                                value={
                                  data?.complain?.created_at
                                    ? dayjs(data?.complain?.created_at).format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""
                                }
                              />
                            </Grid>
                            <Grid xs={12} md={12} item>
                              <TextField
                                multiline
                                rows={3}
                                shrink
                                fullWidth
                                label="Content"
                                value={data?.complain?.content}
                              />
                            </Grid>
                          </Grid>
                          {imgComplain && (
                            <Typography
                              variant="body1"
                              sx={{ marginTop: "10px", marginBottom: "5px" }}
                            >
                              Image Complain
                            </Typography>
                          )}
                          <Grid container spacing={3}>
                            {imgComplain &&
                              imgComplain?.map((item) => (
                                <Grid xs={12} md={6} item key={item}>
                                  <img
                                    style={{
                                      width: "200px",
                                      borderRadius: "20px",
                                      overflow: "hidden",
                                    }}
                                    src={portImg + item}
                                    alt={"Bank Image"}
                                    loading="lazy"
                                  />
                                </Grid>
                              ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid xs={12} md={6} item>
                <Typography noWrap variant="h5">
                  Buyer
                </Typography>
                <Grid container spacing={3} sx={{ marginTop: "10px" }}>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Username"
                      value={data?.buyer?.username}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Email"
                      value={data?.buyer?.email}
                    />
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Accordion>
                      <AccordionSummary
                        sx={{ minHeight: "55px" }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography> Image Transfer</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{
                              width: "200px",
                              borderRadius: "20px",
                              overflow: "hidden",
                            }}
                            src={portImg + data?.bank_img}
                            alt={"Bank Image"}
                            loading="lazy"
                          />
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  {data?.complain?.type === "BUYER" && (
                    <Grid xs={12} md={12} item>
                      <Accordion>
                        <AccordionSummary
                          sx={{ minHeight: "55px" }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>Complain</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={3}>
                            <Grid xs={12} md={12} item>
                              <TextField
                                shrink
                                fullWidth
                                label="Created At"
                                value={
                                  data?.complain?.created_at
                                    ? dayjs(data?.complain?.created_at).format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""
                                }
                              />
                            </Grid>
                            <Grid xs={12} md={12} item>
                              <TextField
                                multiline
                                rows={3}
                                shrink
                                fullWidth
                                label="Content"
                                value={data?.complain?.content}
                              />
                            </Grid>
                          </Grid>
                          {imgComplain && (
                            <Typography
                              variant="body1"
                              sx={{ marginTop: "10px", marginBottom: "5px" }}
                            >
                              Image Complain
                            </Typography>
                          )}
                          <Grid container spacing={3}>
                            {imgComplain &&
                              imgComplain?.map((item) => (
                                <Grid xs={12} md={6} item key={item}>
                                  <img
                                    style={{
                                      width: "200px",
                                      borderRadius: "20px",
                                      overflow: "hidden",
                                    }}
                                    src={portImg + item}
                                    alt={"Bank Image"}
                                    loading="lazy"
                                  />
                                </Grid>
                              ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid xs={12} md={12} item>
                <Typography noWrap variant="h5">
                  Information
                </Typography>
                <Grid container spacing={3} sx={{ marginTop: "10px" }}>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Amount"
                      value={formatMoney(data?.amount)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {data?.init}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Price"
                      value={formatMoney(data?.price)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {data?.currency?.currency_code}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Currency"
                      value={formatMoney(data?.amount_currency)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {data?.currency?.currency_code}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Fee"
                      value={formatMoney(data?.fee)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {data?.init}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Bank Key"
                      value={data?.bank_key}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Create At"
                      value={
                        data?.created_at
                          ? dayjs(data?.created_at).format(
                              "YYYY-MM-DD h:mm:ss A"
                            )
                          : ""
                      }
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Confirm At"
                      value={
                        data?.confirm_at
                          ? dayjs(data?.confirm_at).format(
                              "YYYY-MM-DD h:mm:ss A"
                            )
                          : ""
                      }
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Order At"
                      value={
                        data?.order_at
                          ? dayjs(data?.order_at).format("YYYY-MM-DD h:mm:ss A")
                          : ""
                      }
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Success At"
                      value={
                        data?.success_at
                          ? dayjs(data?.success_at).format(
                              "YYYY-MM-DD h:mm:ss A"
                            )
                          : ""
                      }
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <TextField
                      shrink
                      fullWidth
                      label="Status"
                      value={data?.status}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {dataChat.length > 0 && (
                <Grid xs={12} md={12} item>
                  <Chat dataChat={dataChat} p2pId={data?.id} />
                </Grid>
              )}
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ minHeight: "60px" }}>
        <Button
          size="large"
          onClick={handleCloseDetails}
          color="error"
          variant="outlined"
        >
          Close
        </Button>
        <Button
          size="large"
          onClick={() =>
            handleUpdateStatusP2P({ id: data?.id, status: "CANCEL" })
          }
          color="warning"
          variant="outlined"
          disabled={data?.status !== "DECIDED"}
        >
          Cancel
        </Button>
        <Button
          size="large"
          onClick={() =>
            handleUpdateStatusP2P({ id: data?.id, status: "APPROVE" })
          }
          color="primary"
          variant="contained"
          disabled={data?.status !== "DECIDED"}
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DetailsP2P;
