import CreateApi from "../../Api/CreateApi"
import reduxToolkit from "../../core/reduxToolkit"

const initialState = {
    getvideo: [],
    listCategory: JSON.parse(localStorage.getItem("category")) || null,
    listLanguage: JSON.parse(localStorage.getItem("Language")) || null,

}

export const HandleGetVideo = (page) => {
    return async (dispatch) => {

        try {
            let res = await CreateApi.CreateApiS('public/api/video/getAllVideo', {
                "limit": 8,
                "page": page
            }, true)
            dispatch(getVideo(res?.data?.data))

        } catch (error) {

        }

    }
}

export const GetVideoToCategoryLanguage = (page, idCategorie, idLanguage) => {
    return async (dispatch) => {
        try {
            let res = await CreateApi.CreateApiS('public/api/video/getVideoToIdCategorieAndIdLanguage', {
                "limit": 8,
                "page": page,
                "idCategorie": idCategorie,
                "idLanguage": idLanguage,
            }, true)
            dispatch(getVideo(res?.data?.data))

        } catch (error) {
            // dispatch(getVideo([]))
        }
    }
}
export const HandleGetListCategory = (page) => {
    return async (dispatch) => {
        try {
            let res = await CreateApi.CreateApiSSS('public/api/admin/getCategorie', true)
            localStorage.setItem('category', JSON.stringify(res?.data?.data))
            dispatch(GetCategory(res?.data?.data))
        } catch (error) {
        }
    }
}


export const HandleGetListLanguage = () => {
    return async (dispatch) => {
        try {
            let res = await CreateApi.CreateApiSSS('public/api/admin/getLanguage', true)
            localStorage.setItem('Language', JSON.stringify(res?.data?.data))
            dispatch(GetLanguage(res?.data?.data))
        } catch (error) {
        }
    }
}


let { reducer, action, TYPE } = reduxToolkit({
    name: 'video',
    initialState,
    reducers: {

        getVideo: function (state, action) {

            return {
                ...state,
                getvideo: action.payload
            }
        },
        GetCategory: function (state, action) {

            return {
                ...state,
                listCategory: action.payload
            }
        },
        GetLanguage: function (state, action) {

            return {
                ...state,
                listLanguage: action.payload
            }
        },


    }
})

export default reducer
export const VIDEO = TYPE
export const { getVideo, GetCategory, GetLanguage } = action