import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";

const initialState = {
  getVerifyLocationData: null,
  getVerifyLocationLoading: false,
  getVerifyLocationError: "",

  updateLocationData: null,
  updateLocationLoading: false,
  updateLocationError: "",

  detailLocationData: null,
  detailLocationLoading: false,
  detailLocationError: "",
};

export const actionGetVerifyLocation = (data, isLoading = true) => {
  return async (dispatch) => {
    dispatch(getVerifyLocationStart(isLoading));
    try {
      const { page, limit, keyword, status } = data;
      const apiEndpoint = `api/v1/admin/manage-user/get-verify-location?page=${
        page || 1
      }&limit=${limit || 10}${
        keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
      }${status ? `&status=${encodeURIComponent(status)}` : ""}
      `;

      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getVerifyLocationSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getVerifyLocationFail());
    }
  };
};

export const actionDetailLocation = (id) => {
  return async (dispatch) => {
    dispatch(detailLocationStart());
    try {
      const apiEndpoint = `api/v1/admin/manage-user/get-verify-location/${id}`;
      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(detailLocationSuccess(res?.data?.data));
        return res?.data;
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(detailLocationFail());
    }
  };
};

export const actionUpdateLocation = (data) => {
  return async (dispatch) => {
    dispatch(updateLocationStart());
    const id = toast.loading("Please wait....");
    try {
      const apiEndpoint = `api/v1/admin/manage-user/verify-location`;
      let res = await CreateApi.CreateApiSV2(apiEndpoint, data, true);
      if (res.status === 200) {
        dispatch(updateLocationSuccess(res?.data?.data));
        toast.update(id, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        return res?.data;
      } else {
        toast.update(id, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(updateLocationFail());
      toast.update(id, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "business",
  initialState,
  reducers: {
    getVerifyLocationStart: (state, isLoading) => {
      state.getVerifyLocationLoading = isLoading.payload;
    },
    getVerifyLocationSuccess: (state, action) => {
      state.getVerifyLocationLoading = false;
      state.getVerifyLocationData = action.payload;
      state.getVerifyLocationError = "";
    },
    getVerifyLocationFail: (state, action) => {
      state.getVerifyLocationLoading = false;
      state.getVerifyLocationError = action.payload;
      state.getVerifyLocationData = {};
    },

    detailLocationStart: (state) => {
      state.detailLocationLoading = true;
    },
    detailLocationSuccess: (state, action) => {
      state.detailLocationLoading = false;
      state.detailLocationData = action.payload;
      state.detailLocationError = "";
    },
    detailLocationFail: (state, action) => {
      state.detailLocationLoading = false;
      state.detailLocationError = action.payload;
      state.detailLocationData = {};
    },

    updateLocationStart: (state) => {
      state.updateLocationLoading = true;
    },
    updateLocationSuccess: (state, action) => {
      state.updateLocationLoading = false;
      state.updateLocationData = action.payload;
      state.updateLocationError = "";
    },
    updateLocationFail: (state, action) => {
      state.updateLocationLoading = false;
      state.updateLocationError = action.payload;
      state.updateLocationData = {};
    },
  },
});

export default reducer;
export const brand = TYPE;
export const {
  getVerifyLocationStart,
  getVerifyLocationSuccess,
  getVerifyLocationFail,
  detailLocationStart,
  detailLocationSuccess,
  detailLocationFail,
  updateLocationStart,
  updateLocationSuccess,
  updateLocationFail,
} = action;
