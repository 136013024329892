import React from "react";
import PrivateRoute from "./layout/PrivateRoute";
import PrivateRoute2 from "./layout/PrivateRoute2";

import ManageBrand from "./page/Brand";
import P2P from "./page/P2P";
import GiftCard from "./page/gift-card";
const Category = React.lazy(() => import("./page/Category"));
const Transaction = React.lazy(() => import("./page/transaction"));
const History = React.lazy(() => import("./page/history"));
const KycCompleted = React.lazy(() => import("./page/kycCompleted"));
const Deposit = React.lazy(() => import("./page/deposit"));
const Admin = React.lazy(() => import("./page/admin"));
const Widthdraw = React.lazy(() => import("./page/widthdraw"));
const ManageCoin = React.lazy(() => import("./page/manageCoin"));
const ManageCurrency = React.lazy(() => import("./page/manageCurrency"));
const ManageMasterCard = React.lazy(() => import("./page/manageMasterCard"));
const Config = React.lazy(() => import("./page/config"));
const NewLogin = React.lazy(() => import("./page/NewLogin"));
const Page404 = React.lazy(() => import("./page/page404"));
const TrackingBalance = React.lazy(() => import("./page/trackingBalance"));
const VerifyBusiness = React.lazy(() => import("./page/verifyBusiness"));
const KYC = React.lazy(() => import("./page/kyc"));
const ManageVoucher = React.lazy(() => import("./page/manageVoucher"));

const routers = [
  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      {
        path: "/",
        element: <Admin />,
      },
      {
        path: "/manage-coin",
        element: <ManageCoin />,
      },
      {
        path: "/manage-currency",
        element: <ManageCurrency />,
      },
      {
        path: "/manage-master-card",
        element: <ManageMasterCard />,
      },
      {
        path: "/manage-voucher",
        element: <ManageVoucher />,
      },
      {
        path: "/config",
        element: <Config />,
      },
      {
        path: "/widthdraw",
        element: <Widthdraw />,
      },
      {
        path: "/gift-card-partner",
        element: <GiftCard />,
      },
      {
        path: "/manage-brand",
        element: <ManageBrand />,
      },
      {
        path: "/verify-business",
        element: <VerifyBusiness />,
      },
      {
        path: "/kyc",
        element: <KYC />,
      },
      {
        path: "/tracking-balance",
        element: <TrackingBalance />,
      },
      {
        path: "/p2p",
        element: <P2P />,
      },
      {
        path: "/deposit",
        element: <Deposit />,
      },

      {
        path: "/Category",
        element: <Category />,
      },
      {
        path: "/transaction",
        element: <Transaction />,
      },
      {
        path: "/transfer",
        element: <History />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "/user",
    element: <PrivateRoute2 />,
    children: [
      {
        path: "",
        element: <Admin />,
      },
      {
        path: "widthdraw",
        element: <Widthdraw />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "/login",
    element: <NewLogin />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default routers;
