import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { portImg } from "../../../port";
import {
  SCHEMA_CREATE_BRAND,
  SCHEMA_UPDATE_BRAND,
} from "../../admin/components/validate";
import CustomAutocomplete from "../../../components/RHFCom/CustomAutocomplete";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileUploaderSingle from "../../../components/RHFCom/FileUploaderSingle";
import { actionUpdateBrand } from "../../../redux/reducers/brandReducer";
import LoaderStyleOnes from "../../../components/Loaders/LoaderStyleOne";
function DetailsBrand({
  detailBrand,
  openDetails,
  handleCloseDetails,
  handleUpdateStatus,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    getListCountryData,
    getListCountryLoading,
    getListPlaceData,
    getListPlaceLoading,
    updateBrandLoading,
  } = useSelector((state) => state.brand);

  const listCountry = Array.isArray(getListCountryData)
    ? getListCountryData.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    : [];

  const listPlace = Array.isArray(getListPlaceData?.rows)
    ? getListPlaceData.rows.map((item) => {
        return { label: item?.name, value: item?.id };
      })
    : [];

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SCHEMA_UPDATE_BRAND),
  });

  const onSubmit = (data) => {
    dispatch(
      actionUpdateBrand({
        ...data,
        place_id: data?.place_id?.value,
        country_id: data?.country_id?.value,
        active: data?.active === "true" ? "1" : "0",
        id: detailBrand?.id,
      })
    ).then((res) => {
      if (res?.status) {
        handleUpdateStatus();
        handleCloseDetails();
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    if (detailBrand) {
      setValue("name", detailBrand?.name);
      setValue("country_id", {
        label: detailBrand?.country?.name,
        value: detailBrand?.country_id,
      });
      setValue("place_id", {
        label: detailBrand?.place?.name,
        value: detailBrand?.place_id,
      });
      setValue("description", detailBrand?.description);
      setValue("rule", detailBrand?.rule);
      setValue("active", detailBrand?.active);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [detailBrand]);

  return (
    <Dialog maxWidth="lg" open={openDetails} onClose={handleCloseDetails}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Detail Brand {detailBrand?.name}</DialogTitle>
        <DialogContent dividers sx={{ p: 3, width: "100%" }}>
          {loading || getListCountryLoading || getListPlaceLoading ? (
            <Grid sx={{ width: "1150px" }} container spacing={3}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "30px",
                  marginBottom: "15px",
                }}
              >
                <Skeleton
                  animation="wave"
                  sx={{ transform: "scale(1, 1)", borderRadius: "50%" }}
                  width={200}
                  height={200}
                />
              </Box>
              <Grid xs={12} md={6} item>
                <Skeleton
                  animation="wave"
                  sx={{ transform: "scale(1, 1)" }}
                  height={55}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <Skeleton
                  animation="wave"
                  sx={{ transform: "scale(1, 1)" }}
                  height={55}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <Skeleton
                  animation="wave"
                  sx={{ transform: "scale(1, 1)" }}
                  height={55}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <Skeleton
                  animation="wave"
                  sx={{ transform: "scale(1, 1)" }}
                  height={55}
                />
              </Grid>

              <Grid xs={12} md={12} item>
                <Skeleton
                  animation="wave"
                  sx={{ transform: "scale(1, 1)" }}
                  height={100}
                />
              </Grid>
              <Grid xs={12} md={12} item>
                <Skeleton
                  animation="wave"
                  sx={{ transform: "scale(1, 1)" }}
                  height={250}
                />
              </Grid>
            </Grid>
          ) : (
            <Box>
              <Grid container spacing={3}>
                <Grid xs={12} md={12} item>
                  <Grid container spacing={3} sx={{ marginTop: "10px" }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FileUploaderSingle
                        setValue={setValue}
                        trigger={trigger}
                        error={errors.image}
                        name="image"
                        image={portImg + detailBrand?.images}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid xs={12} md={12} item>
                  <Grid container spacing={3} sx={{ marginTop: "10px" }}>
                    <Grid xs={12} md={6} item>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            label="Name"
                            {...field}
                            error={Boolean(errors.name)}
                            helperText={errors.name?.message || ""}
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <Controller
                        name="country_id"
                        control={control}
                        render={({ field }) => (
                          <CustomAutocomplete
                            fullWidth
                            options={listCountry}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Country"
                                error={Boolean(errors.country_id)}
                                helperText={errors.country_id?.message || ""}
                              />
                            )}
                            {...field}
                            onChange={(_, option, reason) => {
                              field.onChange(option);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <Controller
                        name="place_id"
                        control={control}
                        render={({ field }) => (
                          <CustomAutocomplete
                            fullWidth
                            options={listPlace}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category"
                                error={Boolean(errors.place_id)}
                                helperText={errors.place_id?.message || ""}
                              />
                            )}
                            {...field}
                            onChange={(_, option, reason) => {
                              field.onChange(option);
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid xs={12} md={6} item>
                      <FormControl fullWidth>
                        <InputLabel id="test-select-label">Active</InputLabel>
                        <Controller
                          name="active"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId="test-select-label"
                              fullWidth
                              label="Active"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={Boolean(errors.active)}
                              helperText={errors.active?.message || ""}
                            >
                              <MenuItem value="true">Active</MenuItem>
                              <MenuItem value="false">In Active</MenuItem>
                            </Select>
                          )}
                        />
                        {/* {errors && errors?.coin?.message ? (
                          <span
                            className="text-xs mt-1 pl-3
                   text-qred"
                          >
                            {errors?.coin?.message}
                          </span>
                        ) : (
                          ""
                        )} */}
                      </FormControl>
                    </Grid>
                    <Grid xs={12} md={12} item>
                      <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            label="Description"
                            {...field}
                            multiline
                            rows={3}
                            shrink
                            error={Boolean(errors.description)}
                            helperText={errors.description?.message || ""}
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={12} md={12} item>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <Typography variant="body2" sx={{ fontWeight: "500" }}>
                          Terms And Conditions
                        </Typography>
                        <Controller
                          name="rule"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <CKEditor
                              editor={ClassicEditor}
                              style={{
                                width: "90%",
                                margin: "30px !important",
                                padding: "30px !important",
                              }}
                              data={field.value}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                field.onChange(data);
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ minHeight: "60px" }}>
          <Button
            size="large"
            onClick={handleCloseDetails}
            color="error"
            variant="outlined"
          >
            Close
          </Button>
          <Button
            size="large"
            type="submit"
            color="primary"
            variant="contained"
            disabled={updateBrandLoading}
          >
            Update{" "}
            {updateBrandLoading && (
              <Typography
                sx={{
                  height: "5px",
                  width: "5px",
                  transform: "scale(0.3)",
                  display: "flex",
                  alignItems: "center",
                  marginX: "3px",
                }}
              >
                <LoaderStyleOnes />
              </Typography>
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DetailsBrand;
