import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import React, { useEffect, useState } from "react";

import ListP2P from "./Components/ListP2P";
import ListDecidedP2P from "./Components/ListDecidedP2P";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
function P2P() {
  const [menu, setMenu] = useState("COIN");

  const HandleMenu = () => {
    return (
      <ul style={{ width: "initial", padding: "0px 10px" }}>
        <li className="list active">
          <a onClick={() => setMenu("COIN")}>
            <span className="icon">
              <CurrencyExchangeIcon />
            </span>
            <span
              className="text"
              style={{ minWidth: "150px", paddingTop: "10px" }}
            >
              LIST P2P
            </span>
          </a>
        </li>
        <li className="list">
          <a onClick={() => setMenu("DECIDED_P2P")}>
            <span className="icon">
              <SyncDisabledIcon />
            </span>
            <span
              className="text"
              style={{ minWidth: "100px", paddingTop: "6px" }}
            >
              List Decided P2P
            </span>
          </a>
        </li>
        <div className="indicator"></div>
      </ul>
    );
  };

  useEffect(() => {
    const list = document.querySelectorAll(".list");
    function activeMenu() {
      list.forEach((item) => {
        item.classList.remove("active");
        this.classList.add("active");
      });
    }
    list.forEach((item) => {
      item.addEventListener("click", activeMenu);
    });
  }, []);

  return (
    <>
      <div className="MiniMenu" style={{ paddingTop: "40px" }}>
        <div
          className="navigation"
          style={{
            background: "#A60A5C",
            width: "250px",
          }}
        >
          {HandleMenu()}
        </div>
      </div>
      <div className="content-wrapper">
        {menu == "COIN" && <ListP2P />}
        {menu == "DECIDED_P2P" && <ListDecidedP2P />}
      </div>
    </>
  );
}

export default P2P;
