import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";

const initialState = {
  listWallet: "",
  isListWallet: false,
  listTransaction: "",
  isListTransaction: false,
  listBotHolder: "",
  isListBotHolder: false,
  isError: false,
};

export const GetListWallet = (body) => {
  return async (dispatch) => {
    dispatch(getListWalletStart());
    try {
      let res = await CreateApi.CreateApiGetNode(
        `api/v1/wallet/list`,
        body,
        true
      );
      dispatch(getListWalletSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getListWalletFail());
  };
};

export const GetListTransaction = (body) => {
  return async (dispatch) => {
    dispatch(getListTransactionStart());
    try {
      let res = await CreateApi.CreateApiGetNode(
        `api/v1/transaction/list`,
        body,
        true
      );
      dispatch(getListTransactionSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getListTransactionFail());
  };
};

export const GetListBotHolder = (body) => {
  return async (dispatch) => {
    dispatch(getListBotHolderStart());
    try {
      let res = await CreateApi.CreateApiGetNode(
        `api/v1/botholder/list`,
        body,
        true
      );
      dispatch(getListBotHolderSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getListBotHolderFail());
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "transaction",
  initialState,
  reducers: {
    getListWalletStart: (state) => {
      state.isListWallet = true;
      state.isError = initialState.isError;
    },
    getListWalletSuccess: (state, action) => {
      state.listWallet = action.payload;
      state.isListWallet = false;
      state.isError = initialState.isError;
    },
    getListWalletFail: (state, action) => {
      state.isError = action.payload;
      state.isListWallet = false;
    },
    getListTransactionStart: (state) => {
      state.isListTransaction = true;
      state.isError = initialState.isError;
    },
    getListTransactionSuccess: (state, action) => {
      state.listTransaction = action.payload;
      state.isListTransaction = false;
      state.isError = initialState.isError;
    },
    getListTransactionFail: (state, action) => {
      state.isError = action.payload;
      state.isListTransaction = false;
    },
    getListBotHolderStart: (state) => {
      state.isListBotHolder = true;
      state.isError = initialState.isError;
    },
    getListBotHolderSuccess: (state, action) => {
      state.listBotHolder = action.payload;
      state.isListBotHolder = false;
      state.isError = initialState.isError;
    },
    getListBotHolderFail: (state, action) => {
      state.isError = action.payload;
      state.isListBotHolder = false;
    },
  },
});

export default reducer;
export const transaction = TYPE;
export const {
  getListWalletStart,
  getListWalletSuccess,
  getListWalletFail,
  getListTransactionStart,
  getListTransactionSuccess,
  getListTransactionFail,
  getListBotHolderStart,
  getListBotHolderSuccess,
  getListBotHolderFail,
} = action;
