import CreateApi from "../../Api/CreateApi"
import reduxToolkit from "../../core/reduxToolkit"
import { Loadding } from "./isDifferentReducer"

const initialState = {
    getListBuyP2p: '',
    getListSellP2p: '',
    getPageSell: 1,
    getPageBuy: 1,

}

export const HandleGetListBuyP2p = (page) => {
    return async (dispatch) => {
        dispatch(Loadding(true))
        try {
            let res = await CreateApi.CreateApiS('api/admin/getBuyListP2PAdmin', {
                "limit": 10,
                "page": page,
            }, true)
            dispatch(GetListBuyP2p({ res: res?.data?.data, page: page }))
        } catch (error) {
        }
        dispatch(Loadding(false))

    }
}
export const HandleGetListSellP2p = (page) => {
    return async (dispatch) => {
        dispatch(Loadding(true))

        try {
            let res = await CreateApi.CreateApiS('api/admin/getSellListP2PAdmin', {
                "limit": 10,
                "page": page,
            }, true)
            dispatch(GetListSellP2p({ res: res?.data?.data, page: page }))

        } catch (error) {
        }
        dispatch(Loadding(false))
    }
}

let { reducer, action, TYPE } = reduxToolkit({
    name: 'getlist',
    initialState,
    reducers: {
        GetListBuyP2p: function (state, action) {
            return {
                ...state,
                getListBuyP2p: action.payload?.res,
                getPageBuy: action.payload?.page

            }
        },
        GetListSellP2p: function (state, action) {
            return {
                ...state,
                getListSellP2p: action.payload?.res,
                getPageSell: action.payload?.page

            }
        },



    }
})

export default reducer
export const GETLIST = TYPE
export const { GetListBuyP2p, GetListSellP2p, GetPageAds } = action