import * as Yup from "yup";
import { object, string, number, boolean } from "yup";

export const SCHEMA_EDIT_CURRENCY = object().shape({
  exchange_rate: string().required("Exchange Rate is required"),
  enable_giftcard: Yup.boolean().required("Enable Giftcard is required"),
  auto_update: boolean().required("Auto Update is required"),
  active: boolean().required("Active is required"),
});

export const SCHEMA_EDIT_COIN = object().shape({
  id: number().required("ID is required"),
  token_key: string().required("Token Key is required"),
  name: string()
    .required("Name is required")
    .max(100, "Name cannot exceed 100 characters"),
  price: number().required("Price is required"),
  flag: number().required("Flag is required"),
  set_price: number().required("Set Price is required"),
  percent: number().required("Percent is required"),
  volume: number().required("Volume is required"),
  type: string().required("Type is required"),
  blockchain: string().required("Blockchain is required"),
  minimum_widthdraw: number()
    .required("Minimum Withdraw is required")
    .min(1, "Minimum Withdraw must be at least 1"),
  max_withdraw: number()
    .required("Max Withdraw is required")
    .min(0, "Max Withdraw must be at least 0"),
  fee_widthdraw: number().min(0, "Fee Withdraw must be between 0 and 100"),
  staking: number().required("Staking is required"),
  lendding: number().required("Lending is required"),
  enable_p2p: number().required("Enable P2P is required"),
  price_difference_p2p: number().min(
    0,
    "Price Difference P2P must be at least 0"
  ),
  fee_p2p: number().min(0, "Fee P2P must be between 0 and 100"),
  transfer: number().required("Transfer is required"),
  deposit: number().required("Deposit is required"),
  fee_swap: number().min(0, "Fee Swap must be between 0 and 100"),
  fee_transfer: number().min(0, "Fee Transfer must be between 0 and 100"),
  max_swap: number()
    .required("Max Swap is required")
    .min(0, "Max Swap must be at least 0"),
  min_swap: number()
    .required("Min Swap is required")
    .min(0, "Min Swap must be at least 0"),
  max_transfer: number()
    .required("Max Transfer is required")
    .min(0, "Max Transfer must be at least 0"),
  min_transfer: number()
    .required("Min Transfer is required")
    .min(0, "Min Transfer must be at least 0"),
  is_active: number().required("Is Active is required"),
  enable_giftcard: number().required("Enable Gift Card is required"),
  master_card: number().required("Master Card is required"),
  swap_token: number().required("Swap Token is required"),
});

export const SCHEMA_EDIT_CONFIG = object().shape({
  value: Yup.number()
    .required("Value is required")
    .typeError("Value this is number"),
});

export const schemaModalEditCoin = object().shape({
  coin_amount: Yup.number()
    .required("Coin amount is required")
    .typeError("Coin amount this is number"),
  coin_name: Yup.string()
    .required("Coin name is required")
    .typeError("Coin name is required"),
});

export const SCHEMA_UPDATE_FEE = Yup.object().shape({
  fee: Yup.number()
    .required("Additional Fee is required")
    .positive()
    .min(0)
    .max(100)
    .typeError("Additional Fee is required"),
});

export const SCHEMA_CREATE_BRAND = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(100, "Name cannot exceed 100 characters"),
  images: Yup.string().required("Images is required"),
  active: Yup.string().required("Active is required"),
  rule: Yup.string()
    .required("Terms & conditions is required")
    .max(5000, "Terms & conditions cannot exceed 5000 characters"),
  place_id: Yup.object()
    .required("Category is required")
    .typeError("Category is required"),
  country_id: Yup.object()
    .required("Country is required")
    .typeError("Country is required"),
  description: Yup.string()
    .required("Description is required")
    .max(2000, "Description cannot exceed 2000 characters"),
});

export const SCHEMA_UPDATE_BRAND = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(100, "Name cannot exceed 100 characters"),
  active: Yup.string().required("Active is required"),
  rule: Yup.string()
    .required("Terms & conditions is required")
    .max(5000, "Terms & conditions cannot exceed 5000 characters"),
  place_id: Yup.object()
    .required("Category is required")
    .typeError("Category is required"),
  country_id: Yup.object()
    .required("Country is required")
    .typeError("Country is required"),
  description: Yup.string()
    .required("Description is required")
    .max(2000, "Description cannot exceed 2000 characters"),
});

export const SCHEMA_CONFIRM_REJECT = Yup.object().shape({
  required_message: Yup.string().max(
    2000,
    "Reason Message cannot exceed 2000 characters"
  ),
});
