import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";

const initialState = {
  listCurrency: "",
  isListCurrency: "",
  updateCurrency: "",
  isUpdateCurrency: "",
};

export const UpdateCurrency = ({ id, data }) => {
  return async (dispatch) => {
    dispatch(updateCurrencyStart());
    try {
      let res = await CreateApi.CreateApiPutSV2(
        `api/v1/admin/currency/update/${id}`,
        data,
        true
      );
      if (res.status === 200) {
        toast.success(`🎉 ${res?.data?.message} `);
        dispatch(updateCurrencySuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      toast.error(message);
    }
    dispatch(updateCurrencyFail());
  };
};

export const GetListCurrency = (data) => {
  return async (dispatch) => {
    const { page, limit, keyword = "", userid } = data;
    dispatch(getListCurrencyStart());
    try {
      let res = await CreateApi.CreateApiGetV2(
        `api/v1/admin/currency/list?page=${page || 1}&limit=${limit || 10}${
          keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
        }`,
        true
      );
      dispatch(getListCurrencySuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getListCurrencyFail());
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "coin",
  initialState,
  reducers: {
    updateCurrencyStart: (state) => {
      state.isUpdateCurrency = true;
      state.isError = initialState.isError;
    },
    updateCurrencySuccess: (state, action) => {
      state.updateCurrency = action.payload;
      state.isUpdateCurrency = false;
      state.isError = initialState.isError;
    },
    updateCurrencyFail: (state, action) => {
      state.isError = action.payload;
      state.isUpdateCurrency = false;
    },

    getListCurrencyStart: (state) => {
      state.isListCurrency = true;
      state.isError = initialState.isError;
    },
    getListCurrencySuccess: (state, action) => {
      state.listCurrency = action.payload;
      state.isListCurrency = false;
      state.isError = initialState.isError;
    },
    getListCurrencyFail: (state, action) => {
      state.isError = action.payload;
      state.isListCurrency = false;
    },
  },
});

export default reducer;
export const CURRENCY = TYPE;
export const {
  getListCurrencyStart,
  getListCurrencySuccess,
  getListCurrencyFail,
  updateCurrencyStart,
  updateCurrencySuccess,
  updateCurrencyFail,
} = action;
