import CreateApi from "../../Api/CreateApi"
import reduxToolkit from "../../core/reduxToolkit"

const initialState = {
    loadding: false,
    loaddingLogin: false,
    flag: '',
    vndusd: '',
}

export const HandleGetVndUsd = () => {
    return async (dispatch) => {
        dispatch(Loadding(true))

        try {
            let res = await CreateApi.CreateApiSSSSS('api/p2p/exchange/vndusd', true)
            localStorage.setItem("vndusd", res?.data?.data[0]?.raito)
            dispatch(VndUsd(res?.data?.data[0]?.raito))
        } catch (error) {
        }
        dispatch(Loadding(false))

    }
}


let { reducer, action, TYPE } = reduxToolkit({
    name: 'isDifferent',
    initialState,
    reducers: {

        Loadding: function (state, action) {
            return {
                ...state,
                loadding: action.payload
            }
        },
        LoaddingLogin: function (state, action) {
            return {
                ...state,
                loaddingLogin: action.payload
            }
        },
        toggleShadow: function (state, action) {
            return {
                ...state,
                flag: action.payload
            }
        },
        VndUsd: function (state, action) {

            return {
                ...state,
                vndusd: action.payload
            }
        },


    }
})

export default reducer
export const ISDIFFERENT = TYPE
export const { Loadding, toggleShadow, VndUsd, LoaddingLogin } = action