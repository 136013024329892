import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";

const initialState = {
  listcoin: JSON.parse(localStorage.getItem("ListCoin")) || [],
  listCoin: "",
  isListCoin: "",
  updateCoin: "",
  isUpdateCoin: "",
  listConfig: "",
  isListConfig: "",
  updateConfig: "",
  isUpdateConfig: "",
};

export const UpdateConfig = (data) => {
  return async (dispatch) => {
    dispatch(updateConfigStart());
    try {
      let res = await CreateApi.CreateApiSV2(
        `api/v1/admin/config/${data.id}`,
        data.body,
        true
      );
      toast.success(`🎉 ${res?.data?.message} `);
      dispatch(updateConfigSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(updateConfigFail());
  };
};

export const GetListConfig = (data) => {
  return async (dispatch) => {
    const { page, limit, keyword = "", userid } = data;
    dispatch(getListConfigStart());
    try {
      let res = await CreateApi.CreateApiGetV2(
        `api/v1/admin/config/list?page=${page || 1}&limit=${limit || 10}${
          keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
        }`,
        true
      );
      dispatch(getListConfigSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getListConfigFail());
  };
};

export const UpdateCoin = (data) => {
  return async (dispatch) => {
    dispatch(updateCoinStart());
    try {
      let res = await CreateApi.CreateApiPutSV2(
        "api/v1/admin/coin/update",
        data,
        true
      );
      if (res.status === 200) {
        toast.success(`🎉 ${res?.data?.message} `);
        dispatch(updateCoinSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      toast.error(message);
    }
    dispatch(updateCoinFail());
  };
};

export const GetListCoin = (data) => {
  return async (dispatch) => {
    const { page, limit, keyword = "", userid } = data;
    dispatch(getListCoinStart());
    try {
      let res = await CreateApi.CreateApiGetV2(
        `api/v1/admin/coin/list?page=${page || 1}&limit=${limit || 10}${
          keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
        }`,
        true
      );
      dispatch(getListCoinSuccess(res?.data?.data));
    } catch (error) {}
    dispatch(getListCoinFail());
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "coin",
  initialState,
  reducers: {
    updateConfigStart: (state) => {
      state.isUpdateConfig = true;
      state.isError = initialState.isError;
    },
    updateConfigSuccess: (state, action) => {
      state.updateConfig = action.payload;
      state.isUpdateConfig = false;
      state.isError = initialState.isError;
    },
    updateConfigFail: (state, action) => {
      state.isError = action.payload;
      state.isUpdateConfig = false;
    },

    getListConfigStart: (state) => {
      state.isListConfig = true;
      state.isError = initialState.isError;
    },
    getListConfigSuccess: (state, action) => {
      state.listConfig = action.payload;
      state.isListConfig = false;
      state.isError = initialState.isError;
    },
    getListConfigFail: (state, action) => {
      state.isError = action.payload;
      state.isListConfig = false;
    },

    updateCoinStart: (state) => {
      state.isUpdateCoin = true;
      state.isError = initialState.isError;
    },
    updateCoinSuccess: (state, action) => {
      state.updateCoin = action.payload;
      state.isUpdateCoin = false;
      state.isError = initialState.isError;
    },
    updateCoinFail: (state, action) => {
      state.isError = action.payload;
      state.isUpdateCoin = false;
    },

    getListCoinStart: (state) => {
      state.isListCoin = true;
      state.isError = initialState.isError;
    },
    getListCoinSuccess: (state, action) => {
      state.listCoin = action.payload;
      state.isListCoin = false;
      state.isError = initialState.isError;
    },
    getListCoinFail: (state, action) => {
      state.isError = action.payload;
      state.isListCoin = false;
    },

    Listcoin: function (state, action) {
      localStorage.setItem("ListCoin", JSON.stringify(action.payload));
      return {
        ...state,
        listcoin: action.payload,
      };
    },
  },
});

export default reducer;
export const COIN = TYPE;
export const {
  Listcoin,
  getListCoinStart,
  getListCoinSuccess,
  getListCoinFail,
  updateCoinStart,
  updateCoinSuccess,
  updateCoinFail,
  updateConfigStart,
  updateConfigSuccess,
  updateConfigFail,
  getListConfigStart,
  getListConfigSuccess,
  getListConfigFail,
} = action;
