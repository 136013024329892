import {
  Avatar,
  Box,
  Chip,
  Grid,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { portImg } from "../../../port";
import { handleGetSocketChatBot } from "../../../redux/reducers/p2pReducer";
import { InputChatBox } from "./InputChatBox";

const Chat = ({ dataChat, p2pId }) => {
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTo({
        top: messagesEndRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useLayoutEffect(() => {
    scrollToBottom();
    window.addEventListener("resize", scrollToBottom);
    return () => {
      window.removeEventListener("resize", scrollToBottom);
    };
  }, []);

  const sendMessage = (value, selectedFiles) => {
    const message = typeof value === "string" ? value : "";

    if (selectedFiles && selectedFiles.length > 0) {
      dispatch(
        handleGetSocketChatBot({
          p2p: p2pId,
          images: selectedFiles.map((item) => item?.url),
          mess: message,
        })
      );
    } else if (message.trim() !== "") {
      dispatch(
        handleGetSocketChatBot({
          p2p: p2pId,
          images: [],
          mess: message,
        })
      );
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [dataChat]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{ marginBottom: "15px" }}
            className="header-message"
          >
            Chat
          </Typography>
        </Grid>
      </Grid>
      <Grid container component={Paper} sx={{ position: "relative" }}>
        <Grid item xs={12}>
          <List
            sx={{
              maxHeight: "500px",
              overflow: "scroll",
            }}
            ref={messagesEndRef}
          >
            {dataChat?.map((item) => (
              <ListItem key={item?.id}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection:
                      item?.type === "SELLER"
                        ? "row"
                        : item?.type === "ADMIN"
                        ? "row-reverse"
                        : "row",
                    gap:
                      item?.type === "SELLER"
                        ? "0px"
                        : item?.type === "ADMIN"
                        ? "10px"
                        : "0px",
                    width: "100%",

                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ListItemIcon
                    align={
                      item?.type === "SELLER"
                        ? "left"
                        : item?.type === "ADMIN"
                        ? "right"
                        : "left"
                    }
                  >
                    <Avatar alt={item?.user?.username} />
                  </ListItemIcon>
                  <Grid container>
                    <Grid item xs={12}>
                      <ListItemText
                        align={
                          item?.type === "SELLER"
                            ? "left"
                            : item?.type === "ADMIN"
                            ? "right"
                            : "left"
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems:
                              item?.type === "SELLER"
                                ? "start"
                                : item?.type === "ADMIN"
                                ? "end"
                                : "start",
                            justifyContent: "start",
                            flexDirection: "column",
                            gap: "2px",
                          }}
                        >
                          <Tooltip
                            placement={
                              item?.type === "SELLER"
                                ? "right-start"
                                : item?.type === "ADMIN"
                                ? "left-start"
                                : "right-start"
                            }
                            title={
                              item?.created_at
                                ? dayjs(item?.created_at).format(
                                    "MMM DD, YYYY, hh:mm A"
                                  )
                                : ""
                            }
                          >
                            <Typography
                              variant="caption"
                              sx={{ fontWeight: "600" }}
                              color={
                                item?.type === "SELLER"
                                  ? "secondary"
                                  : item?.type === "ADMIN"
                                  ? "primary"
                                  : "#f44336"
                              }
                            >
                              {item?.user?.username}
                            </Typography>
                            <Box>
                              {item?.images?.length > 0 && (
                                <ImageList
                                  sx={{
                                    width: 500,
                                    maxHeight: 450,
                                    marginBottom: "5px",
                                  }}
                                  cols={item?.images?.length}
                                  // rowHeight={164}
                                >
                                  {item?.images?.map((image) => (
                                    <ImageListItem key={image}>
                                      <img
                                        srcSet={`${portImg}${image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${portImg}${image}?w=164&h=164&fit=crop&auto=format`}
                                        alt={image}
                                        loading="lazy"
                                        style={{
                                          borderRadius: "10px",
                                        }}
                                      />
                                    </ImageListItem>
                                  ))}
                                </ImageList>
                              )}
                              {item?.message && <Chip label={item?.message} />}
                            </Box>
                          </Tooltip>
                        </Box>
                      </ListItemText>
                    </Grid>
                    <Grid item xs={12}></Grid>
                  </Grid>
                </Box>
              </ListItem>
            ))}
          </List>
        </Grid>

        <InputChatBox handleSend={sendMessage} />
      </Grid>
    </div>
  );
};

export default Chat;
