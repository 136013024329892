import * as React from "react";
import MainLayout2 from "./MainLayout2";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import socket from "../socket";

export default function PrivateRoute() {
  const user = JSON.parse(localStorage.getItem("auth"));
  // const { login } = useSelector(state => state.auth)
  const location = useLocation();

  if (!user) return <Navigate to="/login" />;
  else if ((location.pathname == "/" && user?.id == 109) || user?.id == 111)
    return <Navigate to="/user" />;
  return user?.id == 1 && <MainLayout2 />;
  // return <MainLayout2 />;
}
