import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import { ErrorMessage } from "../../core/message";
import reduxToolkit from "../../core/reduxToolkit";
import { Loadding } from "./isDifferentReducer";

const initialState = {
  getAllFee: [],
};

export const HandleGetListFee = () => {
  return async (dispatch) => {
    dispatch(Loadding(true));

    try {
      let res = await CreateApi.CreateApiSSS("api/admin/getAllFee", true);
      dispatch(GetAllFee(res));
    } catch (error) {}
    dispatch(Loadding(false));
  };
};
export const HandleResetPriceCoin = (name) => {
  return async (dispatch) => {
    dispatch(Loadding(true));

    try {
      let res = await CreateApi.CreateApiS(
        "api/admin/resetPriceCoin",
        {
          symbol: name,
        },
        true
      );
      toast.success(`🎉 Successful Reset `);
    } catch (error) {}
    dispatch(Loadding(false));
  };
};
let { reducer, action, TYPE } = reduxToolkit({
  name: "fee",
  initialState,
  reducers: {
    GetAllFee: function (state, action) {
      return {
        ...state,
        getAllFee: action.payload,
      };
    },
  },
});

export default reducer;
export const FEE = TYPE;
export const { GetAllFee } = action;
