import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";

const initialState = {
  filterGiftCardData: null,
  filterGiftCardLoading: false,
  filterGiftCardError: "",

  getListGiftCardData: null,
  getListGiftCardLoading: false,
  getListGiftCardError: "",

  updateStatusGiftCardData: null,
  updateStatusGiftCardLoading: false,
  updateStatusGiftCardError: "",

  getListHistoryGiftCardData: null,
  getListHistoryGiftCardLoading: false,
  getListHistoryGiftCardError: "",

  getDetailGiftCardData: null,
  getDetailGiftCardLoading: false,
  getDetailGiftCardError: "",

  updateFeeGiftCardData: null,
  updateFeeGiftCardLoading: false,
  updateFeeGiftCardError: "",
};

export const actionFilterGiftCardPartner = () => {
  return async (dispatch) => {
    dispatch(getFilterGiftCardStart());
    try {
      let res = await CreateApi.CreateApiGetV2(
        "api/v1/common/master/filter-giftcard",
        true
      );
      if (res.status === 200) {
        dispatch(getFilterGiftCardSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getFilterGiftCardFail());
    }
  };
};

export const actionGetGiftCardPartner = (data, isLoading = true) => {
  return async (dispatch) => {
    dispatch(getListGiftCardStart(isLoading));
    try {
      const { page, limit, keyword, categories, countries, active } = data;
      const apiEndpoint = `api/v1/admin/giftcard/giftcard-partner?page=${
        page || 1
      }&limit=${limit || 10}${keyword ? `&keyword=${keyword}` : ""}${
        categories ? `&category=${encodeURIComponent(categories)}` : ""
      }
      ${countries ? `&country=${countries}` : ""}${
        active ? `&active=${active}` : ""
      }`;

      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getListGiftCardSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getListGiftCardFail());
    }
  };
};

export const actionUpdateStatustGiftCardPartner = (data) => {
  return async (dispatch) => {
    dispatch(updateStatusGiftCardStart());
    const ids = toast.loading("Please wait...");
    try {
      const apiEndpoint = `api/v1/admin/giftcard/update-status/${data?.id}`;
      let res = await CreateApi.CreateApiSV2(
        apiEndpoint,
        { active: data?.active },
        true
      );
      if (res.status === 200) {
        dispatch(updateStatusGiftCardSuccess(res?.data?.data));
        toast.update(ids, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1500,
        });
        return res?.data;
      } else {
        toast.update(ids, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1500,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(updateStatusGiftCardFail());
      toast.update(ids, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    }
  };
};

export const actionGetHistoryGiftCardPartner = (data) => {
  return async (dispatch) => {
    dispatch(getListHistoryGiftCardStart());
    try {
      const { page, limit, keyword, categories, countries, status } = data;
      const apiEndpoint = `api/v1/admin/giftcard/history-partner?page=${
        page || 1
      }&limit=${limit || 10}${keyword ? `&keyword=${keyword}` : ""}${
        categories ? `&category=${encodeURIComponent(categories)}` : ""
      }
      ${countries ? `&country=${countries}` : ""}${
        status ? `&status=${status}` : ""
      }`;

      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getListHistoryGiftCardSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getListHistoryGiftCardFail());
    }
  };
};

export const actionDetailGiftCardPartner = (data) => {
  return async (dispatch) => {
    dispatch(getDetailGiftCardStart());
    try {
      const apiEndpoint = `api/v1/admin/giftcard/detail-order-partner/${data}`;
      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getDetailGiftCardSuccess(res?.data?.data));
        return res?.data;
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getDetailGiftCardFail());
    }
  };
};

export const actionUpdateFeetGiftCardPartner = (data) => {
  return async (dispatch) => {
    dispatch(updateFeeGiftCardStart());
    const ids = toast.loading("Please wait...");
    try {
      const apiEndpoint = `api/v1/admin/giftcard/update-fee/${data?.id}`;
      let res = await CreateApi.CreateApiSV2(
        apiEndpoint,
        { fee: data?.fee },
        true
      );
      if (res.status === 200) {
        dispatch(updateFeeGiftCardSuccess(res?.data?.data));
        toast.update(ids, {
          render: `${res.data.message} 👌`,
          type: "success",
          isLoading: false,
          autoClose: 1500,
        });
        return res?.data;
      } else {
        toast.update(ids, {
          render: `${res.data.message} 🥹`,
          type: "error",
          isLoading: false,
          autoClose: 1500,
        });
        return null;
      }
    } catch (error) {
      const message = error.response.data?.message || error.message;
      dispatch(updateFeeGiftCardFail());
      toast.update(ids, {
        render: `${message} 🥹`,
        type: "error",
        isLoading: false,
        autoClose: 1500,
      });
    }
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "giftCard",
  initialState,
  reducers: {
    getFilterGiftCardStart: (state) => {
      state.filterGiftCardLoading = true;
    },
    getFilterGiftCardSuccess: (state, action) => {
      state.filterGiftCardLoading = false;
      state.filterGiftCardData = action.payload;
      state.filterGiftCardError = "";
    },
    getFilterGiftCardFail: (state, action) => {
      state.filterGiftCardLoading = false;
      state.filterGiftCardError = action.payload;
      state.filterGiftCardData = {};
    },
    getListGiftCardStart: (state, isLoading) => {
      state.getListGiftCardLoading = isLoading.payload;
    },
    getListGiftCardSuccess: (state, action) => {
      state.getListGiftCardLoading = false;
      state.getListGiftCardData = action.payload;
      state.getListGiftCardError = "";
    },
    getListGiftCardFail: (state, action) => {
      state.getListGiftCardLoading = false;
      state.getListGiftCardError = action.payload;
      state.getListGiftCardData = {};
    },
    updateStatusGiftCardStart: (state) => {
      state.updateStatusGiftCardLoading = true;
    },
    updateStatusGiftCardSuccess: (state, action) => {
      state.updateStatusGiftCardLoading = false;
      state.updateStatusGiftCardData = action.payload;
      state.updateStatusGiftCardError = "";
    },
    updateStatusGiftCardFail: (state, action) => {
      state.updateStatusGiftCardLoading = false;
      state.updateStatusGiftCardError = action.payload;
      state.updateStatusGiftCardData = {};
    },
    getListHistoryGiftCardStart: (state) => {
      state.getListHistoryGiftCardLoading = true;
    },
    getListHistoryGiftCardSuccess: (state, action) => {
      state.getListHistoryGiftCardLoading = false;
      state.getListHistoryGiftCardData = action.payload;
      state.getListHistoryGiftCardError = "";
    },
    getListHistoryGiftCardFail: (state, action) => {
      state.getListHistoryGiftCardLoading = false;
      state.getListHistoryGiftCardError = action.payload;
      state.getListHistoryGiftCardData = {};
    },
    getDetailGiftCardStart: (state) => {
      state.getDetailGiftCardLoading = true;
    },
    getDetailGiftCardSuccess: (state, action) => {
      state.getDetailGiftCardLoading = false;
      state.getDetailGiftCardData = action.payload;
      state.getDetailGiftCardError = "";
    },
    getDetailGiftCardFail: (state, action) => {
      state.getDetailGiftCardLoading = false;
      state.getDetailGiftCardError = action.payload;
      state.getDetailGiftCardData = {};
    },
    updateFeeGiftCardStart: (state) => {
      state.updateStatusGiftCardLoading = true;
    },
    updateFeeGiftCardSuccess: (state, action) => {
      state.updateFeeGiftCardLoading = false;
      state.updateFeeGiftCardData = action.payload;
      state.updateFeeGiftCardError = "";
    },
    updateFeeGiftCardFail: (state, action) => {
      state.updateFeeGiftCardLoading = false;
      state.updateFeeGiftCardError = action.payload;
      state.updateFeeGiftCardData = {};
    },
  },
});

export default reducer;
export const giftCard = TYPE;
export const {
  getFilterGiftCardStart,
  getFilterGiftCardSuccess,
  getFilterGiftCardFail,
  getListGiftCardStart,
  getListGiftCardSuccess,
  getListGiftCardFail,
  updateStatusGiftCardStart,
  updateStatusGiftCardSuccess,
  updateStatusGiftCardFail,
  getListHistoryGiftCardStart,
  getListHistoryGiftCardSuccess,
  getListHistoryGiftCardFail,
  getDetailGiftCardStart,
  getDetailGiftCardSuccess,
  getDetailGiftCardFail,
  updateFeeGiftCardStart,
  updateFeeGiftCardSuccess,
  updateFeeGiftCardFail,
} = action;
