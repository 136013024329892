import { toast } from "react-toastify";
import CreateApi from "../../Api/CreateApi";
import reduxToolkit from "../../core/reduxToolkit";

const initialState = {
  filterTrackingBalanceData: null,
  filterTrackingBalanceLoading: false,
  filterTrackingBalanceError: "",

  getListTrackingBalanceData: null,
  getListTrackingBalanceLoading: false,
  getListTrackingBalanceError: "",

  getDetailTrackingBalanceData: null,
  getDetailTrackingBalanceLoading: false,
  getDetailTrackingBalanceError: "",
};

export const actionFilterTrackingBalancePartner = () => {
  return async (dispatch) => {
    dispatch(getFilterTrackingBalanceStart());
    try {
      let res = await CreateApi.CreateApiGetV2(
        "api/v1/admin/tracking/list-filter",
        true
      );
      if (res.status === 200) {
        dispatch(getFilterTrackingBalanceSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getFilterTrackingBalanceFail());
    }
  };
};

export const actionGetTrackingBalancePartner = (data, isLoading = true) => {
  return async (dispatch) => {
    dispatch(getListTrackingBalanceStart(isLoading));
    try {
      const { page, limit, keyword, type, id, timeType, startDate, endDate } =
        data;
      const apiEndpoint = `api/v1/admin/tracking?page=${page || 1}&limit=${
        limit || 10
      }${keyword ? `&keyword=${keyword}` : ""}${
        startDate ? `&startDate=${startDate}` : ""
      }${endDate ? `&endDate=${endDate}` : ""}${
        timeType ? `&timeType=${timeType}` : ""
      }${type ? `&type=${encodeURIComponent(type)}` : ""}
      ${id ? `&id=${id}` : ""}`;

      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getListTrackingBalanceSuccess(res?.data?.data));
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getListTrackingBalanceFail());
    }
  };
};

export const actionDetailTrackingBalancePartner = (data) => {
  return async (dispatch) => {
    dispatch(getDetailTrackingBalanceStart());
    try {
      const { id } = data;
      const apiEndpoint = `api/v1/admin/tracking/${id}`;

      let res = await CreateApi.CreateApiGetV2(apiEndpoint, true);
      if (res.status === 200) {
        dispatch(getDetailTrackingBalanceSuccess(res?.data?.data));
        return res?.data?.data;
      } else {
        toast.error(res.data.message);
        return null;
      }
    } catch (error) {
      dispatch(getDetailTrackingBalanceFail());
    }
  };
};

let { reducer, action, TYPE } = reduxToolkit({
  name: "trackingBalance",
  initialState,
  reducers: {
    getFilterTrackingBalanceStart: (state) => {
      state.filterTrackingBalanceLoading = true;
    },
    getFilterTrackingBalanceSuccess: (state, action) => {
      state.filterTrackingBalanceLoading = false;
      state.filterTrackingBalanceData = action.payload;
      state.filterTrackingBalanceError = "";
    },
    getFilterTrackingBalanceFail: (state, action) => {
      state.filterTrackingBalanceLoading = false;
      state.filterTrackingBalanceError = action.payload;
      state.filterTrackingBalanceData = {};
    },
    getListTrackingBalanceStart: (state, isLoading) => {
      state.getListTrackingBalanceLoading = isLoading.payload;
    },
    getListTrackingBalanceSuccess: (state, action) => {
      state.getListTrackingBalanceLoading = false;
      state.getListTrackingBalanceData = action.payload;
      state.getListTrackingBalanceError = "";
    },
    getListTrackingBalanceFail: (state, action) => {
      state.getListTrackingBalanceLoading = false;
      state.getListTrackingBalanceError = action.payload;
      state.getListTrackingBalanceData = {};
    },
    getDetailTrackingBalanceStart: (state) => {
      state.getDetailTrackingBalanceLoading = true;
    },
    getDetailTrackingBalanceSuccess: (state, action) => {
      state.getDetailTrackingBalanceLoading = false;
      state.getDetailTrackingBalanceData = action.payload;
      state.getDetailTrackingBalanceError = "";
    },
    getDetailTrackingBalanceFail: (state, action) => {
      state.getDetailTrackingBalanceLoading = false;
      state.getDetailTrackingBalanceError = action.payload;
      state.getDetailTrackingBalanceData = {};
    },
  },
});

export default reducer;
export const TrackingBalance = TYPE;
export const {
  getFilterTrackingBalanceStart,
  getFilterTrackingBalanceSuccess,
  getFilterTrackingBalanceFail,
  getListTrackingBalanceStart,
  getListTrackingBalanceSuccess,
  getListTrackingBalanceFail,
  getDetailTrackingBalanceStart,
  getDetailTrackingBalanceSuccess,
  getDetailTrackingBalanceFail,
} = action;
