import React, { Suspense } from "react";
import { Provider } from "react-redux";

import { BrowserRouter } from "react-router-dom";
import "./App.css";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./core/RouterConfig";
import store from "./redux";
import TranslateProvider from "./translate";
function App() {
  return (
    <TranslateProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <Suspense fallback={<Loadding />}> */}
              <Routes />
              <ToastContainer />
            </LocalizationProvider>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </TranslateProvider>
  );
}

export default App;
