import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { formatMoney } from "../../../core/convertNumber";
function DetailsGiftcard({ openDetails, handleCloseDetails }) {
  const { getDetailGiftCardData: data, getDetailGiftCardLoading } = useSelector(
    (state) => state.giftCard
  );
  console.log(data);
  return (
    <Dialog maxWidth="md" open={openDetails} onClose={handleCloseDetails}>
      <DialogTitle>Detail Giftcard</DialogTitle>
      <DialogContent dividers sx={{ p: 3, width: "100%" }}>
        {getDetailGiftCardLoading ? (
          <Grid sx={{ width: "800px" }} container columnSpacing={3}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                marginBottom: "15px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Skeleton animation="wave" width={300} height={200} />
            </Box>
            <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
              <Skeleton animation="wave" height={90} />
            </Grid>
            <Grid xs={12} md={12} item sx={{ marginTop: "20px" }}>
              <Skeleton animation="wave" height={300} />
            </Grid>
            <Grid xs={12} md={12} item sx={{ marginTop: "20px" }}>
              <Skeleton animation="wave" height={300} />
            </Grid>
          </Grid>
        ) : (
          <Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                marginBottom: "15px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  width: "200px",
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
                src={data?.imageUrl}
                alt={data?.name}
                loading="lazy"
              />
            </Box>
            <Grid container columnSpacing={3}>
              <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
                <TextField
                  shrink
                  fullWidth
                  label="Username"
                  value={data?.user?.username}
                />
              </Grid>
              <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
                <TextField
                  shrink
                  fullWidth
                  label="Email"
                  value={data?.user?.email}
                />
              </Grid>
              <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
                <TextField shrink fullWidth label="Name" value={data?.name} />
              </Grid>
              <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
                <TextField
                  shrink
                  fullWidth
                  label="Status"
                  value={data?.status}
                />
              </Grid>

              <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
                <TextField shrink fullWidth label="Pin" value={data?.pin} />
              </Grid>
              <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
                <TextField
                  shrink
                  fullWidth
                  label="Amount Coin"
                  value={data?.amountCoin}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {data?.coin}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
                <TextField
                  shrink
                  fullWidth
                  label="Fee"
                  value={formatMoney(data?.fee)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {data?.coin}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
                <TextField
                  shrink
                  fullWidth
                  label="Denomination"
                  value={data?.denomination}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {data?.currency}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
                <TextField
                  shrink
                  fullWidth
                  label="End Date"
                  value={data?.endDate}
                />
              </Grid>
              <Grid xs={12} md={6} item sx={{ marginTop: "20px" }}>
                <TextField
                  shrink
                  fullWidth
                  label="Country"
                  value={`${data?.countryName} (${data?.countryCode})`}
                />
              </Grid>
              <Grid xs={12} md={12} item sx={{ marginTop: "20px" }}>
                <TextField
                  shrink
                  fullWidth
                  label="Categories"
                  value={data?.categories}
                />
              </Grid>
              <Grid xs={12} md={12} item sx={{ marginTop: "20px" }}>
                {/* <TextField shrink fullWidth label="URL" value={} /> */}
                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                  URL
                </Typography>
                <a href={data?.url} target="_blank">
                  {data?.url}
                </a>
              </Grid>
              <Grid xs={12} md={12} item sx={{ marginTop: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "500" }}>
                    Description
                  </Typography>
                  <CKEditor editor={ClassicEditor} data={data?.description} />
                </Box>
              </Grid>

              <Grid xs={12} md={12} item sx={{ marginTop: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "500" }}>
                    Redemption Instructions
                  </Typography>
                  <CKEditor
                    editor={ClassicEditor}
                    data={data?.redemptionInstructions}
                  />
                </Box>
              </Grid>

              <Grid xs={12} md={12} item sx={{ marginTop: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "500" }}>
                    Terms And Conditions Instructions
                  </Typography>
                  <CKEditor
                    editor={ClassicEditor}
                    data={data?.termsAndConditionsInstructions}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDetails}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DetailsGiftcard;
